@import "../../../../common/components/app/globals";
@import "~@impact-x/typography/_variables";

.ApplicationStatus {
  background-color: $grey-custom;
  padding: 0 20px;
  font-size: $ix-font-size-s;
  width: 405px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .status {
    .title {
      font-weight: $ix-font-weight-600;
      font-size: $ix-font-size-m;
    }
  }
}
