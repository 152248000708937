@import "table";

@mixin data-table($highlight-row-on-hover:off) {
  overflow: unset !important;

  div {
    .rdt_Table {
      padding: 0 10px;
      font-size: 14px;

      .rdt_TableHead {

        .rdt_TableHeadRow {
          @include table-header(div);

          .rdt_TableCol {
            @include table-header-cell;

            .rdt_TableCol_Sortable {
              span {
                svg {
                  transition-duration: unset;
                  height: max-content !important;
                  width: unset !important;
                }
              }

              &:hover {
                @include table-header-hover;
              }
            }
          }
        }
      }

      .rdt_TableBody {
        overflow: unset !important;

        .rdt_TableRow {
          @include table-row($highlight-row-on-hover, div);

          .rdt_TableCell {
            @include table-row-cell;

            div {
              white-space: unset;
            }
          }
        }
      }
    }
  }
}
