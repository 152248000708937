@import '~@impact-x/color/_brand';
@import '~@impact-x/color/_neutral';
@import '~@impact-x/typography/_variables';
@import "~@impact-x/styles/_divider";
@import "src/common/components/app/_globals";

.Footer {
  $height: 80px;
  margin-top: $footer-top-margin;

  .spacer {
    background: unset;
    height: $height;
  }

  .FooterWrapper {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: $height;
    color: $ix-color-onyx;
    font-size: $ix-font-size-s;
    background-color: $ix-color-talc;
    display: flex;
    flex-direction: column;

    hr {
      @include ix-hr;
      margin: 0;
    }

    .contents {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 calc(calc(100% - #{$page-content-width}) / 2);
      flex: 1;

      .powered-by {
        display: flex;
        align-items: center;

        img {
          height: 1rem;
        }
      }
    }
  }
}
