@import '~@impact-x/typography/_variables';
@import "src/common/components/app/_globals";

.PageBanner {
  display: flex;
  height: 320px;

  .link {
    flex: 1;
    display: flex;

    &:hover {
      text-decoration: none;
    }
  }

  .banner-container {
    flex: 1;
    display: flex;

    .banner-content {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: calc(calc(100vw - 1200px) / 2);
      padding-right: 120px;

      .banner-message {
        font-size: 2rem;
        letter-spacing: $ix-letter-spacing-600;
        line-height: 48px;

        p {
          text-align: left;
          font-size: 1rem;
          line-height: 28px;
          letter-spacing: 0;
        }

        h1 {
          color: inherit !important;
          font-weight: $ix-font-weight-600 !important;
        }
      }

      .banner-links {
        display: flex;
        justify-content: space-between;

        .NeoLink {
          color: inherit !important;
          border: none !important;
          font-weight: $ix-font-weight-600;
          font-size: 1rem;
          line-height: 28px;

          &:hover {
            text-decoration: underline !important;
          }
        }
      }
    }

    .banner-image {
      width: 50%;
      display: flex;
      background-position: left center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
