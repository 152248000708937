@import "../../../../../common/components/app/_globals";
@import "node_modules/@impact-x/typography/_variables";
@import "node_modules/@impact-x/styles/_select";

.EditApplicationStatus {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    font-weight: $ix-font-weight-600;
    font-size: $ix-font-size-m;
  }

  .select-application-status {
    margin-right: 10px;

    select {
      @include ix-select;
      width: 230px;
      background-color: $background-color;
    }
  }
}
