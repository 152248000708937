@import "src/common/components/app/_globals";

.InfrastructureOfferings {

  @include headings-color($secondary-color);

  .breadcrumb {
    text-align: left;
    margin-top: 22px;
  }

  .offerings-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 60px 42px 50px;
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 2px 4px #00000029;
  }

  .get-started-button {
    margin-top: 39px;
    margin-right: -10px;
    display: flex;
    justify-content: flex-end;
  }

  .space-below-heading {
    width: 100%;
    height: 92px;
    background-color: #26BDCF;
    position: absolute;
    top: auto;
    left: 0;
    z-index: -1;
  }
}
