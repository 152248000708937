@import "../../../../common/components/app/_globals";
@import '../../../../../node_modules/@impact-x/color/_neutral';
@import '../../../../../node_modules/@impact-x/typography/_variables';

.UserActions {
  .DropdownMenu {
    #dropdown-toggle-button {
      font-size: 28px !important;
      bottom: 12px;
      background: none !important;
    }

    .dropdown-menu {
      background: $ix-color-grey-80;

      .dropdown-item {
        color: $ix-color-talc;
        font-weight: $ix-font-weight-700;

        &:hover {
          background-color: #9A9C9E;
          color: $ix-color-grey-80;
        }
      }
    }
  }
}
