@import "~@impact-x/typography/_variables";
@import "src/common/components/app/_globals";

.DetailsList {
  .info-title {
    @include info-title;
  }

  .details-list {
    @include info-value;

    .NeoLink {
      color: $text-color !important;
      border-color: $text-color !important;
      font-weight: $ix-font-weight-400 !important;
    }
  }
}
