@import "src/common/components/app/_globals";
@import url("https://fonts.googleapis.com/css?family=Bitter:700");
@import url("https://fonts.googleapis.com/css?family=Inter:600,700");

.EventsSubHeader {
  position: sticky;
  top: $sub-header-top-position;
  z-index: 1;

  nav.sc-ix-nav .nav-menu-item.sc-ix-nav {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    align-items: center;

    color: #FFFFFF;
    text-align: center;
  }

  nav.sc-ix-nav .nav-header.sc-ix-nav {
    font-family: 'Bitter', sans-serif;
    font-weight: 700;
    font-size: 26.5px;
  }

  .sc-ix-nav-h .sc-ix-nav-s {
    padding-left: 120px;
  }

  nav.sc-ix-nav .nav-menu-item-active.sc-ix-nav {
    font-weight: bold;
    font-family: 'Inter', sans-serif;
  }

  nav.sc-ix-nav-s > ol:last-child:not(:first-child) {
    padding-top: 16px;
  }

  nav.sc-ix-nav .nav-header.sc-ix-nav {
    text-align: left !important;
  }

  .sc-ix-nav > ol {
    flex-grow: 1 !important;
  }

  nav.sc-ix-nav li:nth-child(1) {
    width: 70px !important;
  }

  nav.sc-ix-nav li:nth-child(2) {
    width: 90px !important;
  }
}
