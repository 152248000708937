.cost-alert {
  width: 586px;
  height: 59px;
  flex-shrink: 0;
  background: rgba(160, 105, 8, 0.10);
  color: #A06908;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  padding: 0.75rem;

  .alert-message {
    padding-left: 4px;
  }
}
