@import "src/common/components/app/_globals";

.DeveloperTools {
  .developer-tools-title {
    margin-bottom: 0.75rem;
  }

  .developer-tools-description {
    margin-bottom: 2rem;
    white-space: pre-wrap;
  }

  .developer-tools-content {
    display: flex;
    flex-direction: column;

    .developer-tools-by-category {
      margin-bottom: 3rem;

      .category {
        margin-bottom: 1.5rem;
      }

      .developer-tools-list {
        margin: -0.75rem;

        .developer-tools-column {
          padding: 0.75rem;
        }
      }
    }
  }
}
