@import "src/common/components/app/data-table";
@import "../../../../node_modules/@impact-x/typography/variables";
@import "../../../../node_modules/@impact-x/space/index";
@import "../../../../node_modules/@impact-x/color/index";

.TeamApplications {
  .Heading {
    margin-top: 32px;
    margin-bottom: 16px;
    font-weight: $ix-font-weight-600;
    font-size: $ix-font-size-xl;
  }

  .panel-title {
    padding-bottom: 2rem;
  }
}
