@import "~@impact-x/typography/variables";
@import "src/common/components/app/_globals";

.ApplicationCard {
  .card {
    @include neo-card;
    @include headings-color($secondary-color);
    background-color: #FFFFFF;
    height: 12.75rem !important;
    padding: 1rem 1.5rem;
    border-radius: 0;
    border: 1px solid $onix-black-20;

    .card-body {
      padding: 0;

      .team-type {
        @include small-caps;
        color: $onix-black-60;
      }

      .card-title {
        margin-top: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical
      }

      .description {
        @include small-font;
        color: $onix-black-80;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: pre-wrap;
        max-width: 400px;
      }
    }

    .application-status {
      font-weight: $ix-font-weight-400;
      color: $onix-black-60;
      font-size: $ix-font-size-s;
    }
  }

  &:hover {
    text-decoration: none;
  }
}

