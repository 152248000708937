@import "src/common/components/app/_globals";
@import "src/common/components/app/_button";


.FilterDropDown {
  .title {
    font-size: 1rem;
    color: $text-color;
    margin-bottom: 0.5rem;
  }

  .multi-select {
    min-width: 12.1875rem;
    font-size: 0.875rem;

    .ant-select-selector {
      padding: 0.5rem 0.75rem;
      border-radius: 0 !important;
      border-color: $onix-black-20 !important;

      &:focus-within {
        box-shadow: 0 0 0 3px $secondary-color-focus-state !important;
        outline: 1px solid $secondary-color !important;
        outline-offset: -2px;
      }

      &::after {
        margin: 0;
      }

      .ant-select-selection-overflow {
        .ant-select-selection-item {
          border-radius: 0 !important;
          align-items: center;
          background-color: $mist-grey;
          height: 1.3125rem;
          line-height: 1.3125rem;
          padding: 0 0.25rem;


          .ant-select-selection-item-remove {
            color: rgba(0, 0, 0, 0.6);
            font-size: 1rem;

            & > * {
              line-height: 0;
            }
          }
        }

        .ant-select-selection-search {
          margin-inline-start: 0;
          height: 1.5625rem;
        }
      }

      .ant-select-selection-placeholder {
        color: $onix-black-60;
      }
    }

    .ant-select-clear {
      display: flex;
      right: 1rem;

      .clear-all {
        @include impact-x-icon-button;
        font-size: 1rem;
        line-height: 1;
        padding: 0;
      }
    }
  }
}

.multi-select-option.ant-select-item-option-selected {
  background-color: $mist-grey !important;

  .ant-select-item-option-state {
    color: $onix-black-80 !important;
  }
}
