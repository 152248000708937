@import "~@impact-x/typography/variables";

.Tooltip {
  color: #333333;

  .content {
    width: max-content;
    max-width: 350px;
    text-align: left;
  }

  .tooltip-inner {
    max-width: 350px;
    border-radius: 0;
    padding: 1px 4px;
    font-size: $ix-font-size-xs;
  }
}
