@import "~@impact-x/typography";
@import '~@impact-x/space';

.UserGuide{
  font-family: Inter;
  h1{
    color:#173C4D;
    font-size: 20px !important;
  }
  .api-info{
    h1{
        font-size: 26px !important;
    }
  }

  .menu-content {
    background-color: white !important;
    //top: 248px !important;
    box-shadow: none;
    border: 1px solid #BDBEC0;
  }

  .api-content{
    z-index: 0;
    background: #FFFFFF;
    margin-left: 24px ;
    width: 894px;
    border: 1px solid #BDBEC0;
    box-sizing: border-box;
  }

  .menu-content{
    width: 282px;
    padding-top: 20px;
    height: calc(100vh - 350px) !important;

    span {
      font-size: 16px;
      line-height: 22px;
    }
  }

  h2{
    color:#212223;
    font-size: 18px !important;
  }
  .-depth1{
    border-bottom: 1px solid #E9E9EA;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #212223;
  }
  .-depth1:hover{
    background-color: transparent;
  }
  .-depth1.active{
    background-color: transparent;
    font-weight: 600;
  }
  .-depth2{
    border-bottom: 1px solid #E9E9EA;
  }

  .-depth2:hover{
    background-color: transparent;
  }
  .-depth2.active:hover{
    background-color: transparent;
  }
  .-depth2.active{
    border-right: #173C4D 5px solid;
    background-color: transparent;
    font-weight: 600;
  }
  .api-content{
    z-index:0 !important;
  }
  a[href="https://github.com/Redocly/redoc"] {
    display: none;
  }
  .redoc-markdown{
    p{
      font-size: 16px;
      color: #808285 !important;
      line-height: 1.5;
    }
    li {
      list-style-type: inherit !important;
      color: #808285 !important;
    }

    table{
      border-collapse: collapse;
      border: 2px solid black !important;
      font-size: 16px;
      color: #808285 !important;
      th{
        border: 1px solid gray;
        text-align: center;
      }
      tr{
        border: none;
      }
      td{
        border: 1px solid gray;
        background-color: #F8F8F8;
        margin:5px;
      }
      .border-bottom{
        border-bottom: 2px solid black !important;
      }

      .text-rotation{
        -webkit-transform:rotate(-90deg);
        -moz-transform:rotate(-90deg);
        -o-transform: rotate(-90deg);
        -ms-transform:rotate(-90deg);
        transform: rotate(-90deg);
        text-align: center;
      }
    }
  }
  .redoc-wrap{
    .menu-content{
      font-size: 17.222px !important;
    }
  }
  [data-section-id='section/Introduction']{
    padding-top: 0px;
  }
  .api-content{
    & >:first-child {
      padding-bottom: 10px;
    }
  }
  [data-section-id]{
    padding-bottom:10px;
    padding-top:10px;
  }
  [data-section-id='section/Subscribe-to-Events/API-Service-and-Documentation']{
    padding-top: 0px;
  }
  [data-section-id='section/Publish-Events/API-Service-and-Documentation']{
    padding-top: 0px;
  }
  [data-section-id='section/Events-Platform-CI']{
    padding-top: 0px;
  }
  [data-section-id='section/Events-Platform-CI/Command-line-tool-for-publishing-and-subscribing-to-events']{
    padding-top: 0px;
  }
}
