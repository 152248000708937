@import "../../../common/components/app/_globals";

.NoTeamsMessage {
  font-size: 1rem;
  font: $body-font-family;
  font-weight: $ix-font-weight-400;
  color: #333333;
  text-align: center;
  margin-bottom: 1.5rem;

  .help-link {
    padding: 0;
    border: none;
    @include neo-link;
  }
}

.teams-info {
  display: flex;
  justify-content: space-between;

  .team-info {
    width: 580px;
    height: 430px;
    box-shadow: 0 4px 10px 0 #4A3B5E26;
    padding: 1.5rem 0;
    text-align: center;
    background-color: $background-color;

    .name {
      font-size: 18px;
      font-weight: $ix-font-weight-600;
      margin-bottom: 1rem;
      font-family: Open Sans, serif;
      line-height: 28px;
    }

    .team-description {
      text-align: left !important;
      font-size: 1rem;
      font-weight: $ix-font-weight-400;
      padding: 1rem 1.25rem;
      font-family: Open Sans, serif;
      line-height: 24px;
    }
  }
}
