@import "~@impact-x/typography";
@import '~@impact-x/space';

.EventsOverview{
  font-family: "Open Sans", sans-serif;
  font-size: $ix-font-size-l;
  font-weight: $ix-font-weight-700;
  display: flex;
  margin: auto;
  .banner-section {
    height: 320px;
    border-bottom: 1px solid #000000;
    display: flex;
    .banner-right-block {
      width: 50%;
    }
    .banner-left-block {
      width: 50%;
    .banner-container {
      height: 320px;
      width: 1200px;
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
        .banner-content {
          padding: 80px 0px 63px 0px;

          .banner-content-title {
            font-family: Bitter;
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            line-height: 48px;
          }

          .banner-content-text {
            margin-top: 35px;
            font-family: Inter;
            font-weight: 500;
            font-size: 21px;
            line-height: 32px;
          }
        }
      }
    }
  }
  .overview-content {
    width: 1200px;
    margin: 48px auto;
    .overview {
      .overview-title {
        text-align: center;
        font-style: normal;
        font-weight: 500 !important;
        font-size: 26.4px !important;
        line-height: 28px;
        color: #000000;
      }
      .loading-icon {
        .text {
          font-weight: 500 !important;
        }
      }
      .category-list{
        display: flex;
        .category-item{
          margin-top: 26px;
          width: 109px;
          cursor: pointer;
          .category-icon{
            text-align: center;
          }
          .category-title{
            margin-top: 15.5px;
            text-align: center;
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 18px;
            color: #212223;
          }
        }
      }
      .event-metrics {
        margin-top: 60px;
        margin-bottom: 48px;
        .event-metrics-title{
          font-style: normal;
          font-weight: 500 !important;
          font-size: 26px !important;
          line-height: 22px;
          color: #000000;
        }
        .event-metrics-content{
          margin-top:24px;
          background: #F4F5F8;
          display: flex;
          justify-content: space-around;
          .metric-divider{
            border: 1px solid #BDBEC0;
            width: 0px;
            height: 68px;
            margin-top:24px;
          }
          .metric-item{
            text-align: center;
            padding: 24px;
            .metric-name{
              color: #212223;
              font-family: Open Sans;
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 22px;
            }
            .metric-value{
              margin-top:24px;
              color: #173C4D;
              font-family: Inter;
              font-style: normal;
              font-weight: 900;
              font-size: 32px;
              line-height: 22px;
            }
          }
        }
      }
      .event-overview-error{
        padding: 30px;
      }
    }
  }
    .article-content {
      background: #F4F5F8;
      margin-top: 48px;
      padding-top: 1px;
      padding-bottom: 1px;
      .article-list {
        width: 1200px;
        margin: 48px auto;
        .article-list-title {
          margin-bottom: 24px;
          font-style: normal;
          font-weight: 500 !important;
          font-size: 26px !important;
          line-height: 22px;
          color: #333333;
        }
        .article-wrapper {
          display: flex;
          justify-content: space-between;
          .article-item {
            width: 368px;
            background: white;
            border: 1px solid #BDBEC0;
            padding: 32px;

            .article-name {
              font-family: Bitter;
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 22px;
              color: #333333;
            }

            .article-description {
              margin-top: 12px;
              font-family: Inter;
              font-style: normal;
              font-weight: 300;
              font-size: 16px;
              line-height: 25px;
              color: #333333;
            }

            .article-link {
              margin-top: 32px;

              .link-button {
                border: 1px solid #F2617A;
                background: white;
                font-family: Inter;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 18px;
                color: #F2617A;
              }
            }
          }
        }
      }
    }
      .whats-new {
        width: 1200px;
        margin: 48px auto;
        .whats-new-title{
          margin-bottom: 36px;
          font-style: normal;
          font-weight: 500 !important;
          font-size: 26px !important;
          line-height: 22px;
          color: #333333;
        }
        .feature-wrapper {
          display: flex;
          justify-content: space-between;
          .feature-item {
            width: 576px;
            border: 1px solid #BDBEC0;
            &:hover {
              cursor: pointer;
              border: 1px solid #173C4D;
              box-shadow: 0px 10px 20px rgba(37, 51, 75, 0.2);
            }
            .feature-image {

            }
            .feature-content{
              padding:32px;
              .feature-title{
                font-family: Bitter;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #333333;
              }
              .feature-description{
                margin-top: 12px;
                font-family: Inter;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 25px;
                color: #333333;
              }
              .feature-link{
                color: #E16B7C;
                font-size: 30px;
              }
              :hover{
                text-decoration: none;
              }
            }
          }
        }
      }

}
