@import "~@impact-x/typography/_variables";
@import "src/common/components/app/_globals";

.DeveloperToolCard {
  .card {
    @include neo-card;
    @include headings-color($secondary-color);
    background-color: #FFFFFF;
    height: 12.75rem !important;
    padding: 1rem 1.5rem;
    border-radius: 0;
    border: 1px solid $onix-black-20;

    .card-body {
      padding: 0;

      .card-title {
        margin-top: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical
      }

      .description {
        @include small-font;
        color: $onix-black-80;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: pre-wrap;
      }
    }

    .team-name {
      @include small-font;
      color: $onix-black-60 !important;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  &:hover {
    text-decoration: none;
  }
}

