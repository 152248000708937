@import '~@impact-x/typography/_variables';
@import '~@impact-x/space/_margin';
@import "src/common/components/app/_globals";

.ProjectClassification {
  .apps-catalogue {
    margin-bottom: 32px;
    font-size: $ix-font-size-m;
    font-weight: $ix-font-weight-400;

    .apps-catalogue-link {
      @include neo-link;
    }
  }

  .project-classification-info {
    @include neo-card;

    background: white 0 0 no-repeat padding-box;
    color: $text-color;
    border: 1.25px solid #DDDDDD;
    text-align: left;
    padding: $ix-space-margin-xl $ix-space-margin-xl;
    height: 100%;
  }
}
