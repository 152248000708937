@import "~@impact-x/typography/_variables";
@import "src/common/components/app/globals";

.SelectTeam {
  .select-team-title {
    margin-top: 32px;
  }

  .previous-button {
    margin-top: 20px;
  }

  .content {
    margin-top: 10px;
  }

  .create-team-link {
    @include neo-link;
  }

  span {
    font-size: $ix-font-size-l;
  }
}


.create-team-modal {
  $modal-max-width: 720px;
  max-width: $modal-max-width;
}

.create-team-modal-content {
  margin-top: -30px;
}

.modal[aria-hidden=true] {
  .create-team-modal {
    opacity: 0.8;
  }
}
