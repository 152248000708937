@import "~@impact-x/typography";
@import '~@impact-x/space';
@import '~@impact-x/color/_neutral';

.EventsDiscovery {
  font-family: "Open Sans", sans-serif;
  width: 1200px;
  font-size: $ix-font-size-l;
  font-weight: $ix-font-weight-700;
  display: flex;
  margin: 48px auto;
  //padding-top: 60px;

  .categories-loading-progress {
    padding-top: 50px;
    margin: auto;
  }

  .section-title-selected {
    color: #173C4D;
  }

  .section-title {
    color: #212223;
  }

  h3 {
    //color: #173C4D !important;
    padding-left: 22px;
    margin-bottom: 0px;
  }

  h4 {
    padding-left: 22px;
  }

  .loading-icon {
    padding: 120px 0 0 20px;
  }

  .text {
    font-weight: normal;
    font-size: 16px;
    font-family: Inter, sans-serif;
  }

  .sidebar {
    position: fixed;
    width: 282px;
    height: calc(100% - 350px);
    overflow-y: scroll;
    overflow-x: hidden;
    border: 1px solid #BDBEC0;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }

  .sidebar::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Chrome/Safari/Webkit */
  }

  .sidebar-container {
    //border: 1px solid #BDBEC0;
  }

  .sidebar-title {
    border-bottom: 1px solid #E9E9EA;
    padding: 24px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #212223;
  }

  .sidebar-title-nsview {
    display: flex;
    justify-content: center;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    height: 78px;
    border-bottom: 1px solid #E9E9EA;
    cursor: default;
    color: #212223;
  }

  .sidebar-title-nsview span {
    padding: 24px 16px;
    line-height: 28px;
  }

  .sidebar-title-nsview span:nth-last-child(2) {
    padding: 0px;
  }

  .sidebar-title-separator {
    border-right: 1px solid #BCBCBC;
    margin: 12px 0px;
  }

  .sidebar-title-nsview nav {
    width: 120px;
    padding: 8px;
    margin: auto;
    cursor: default;

    &:hover {
      font-weight: 600;
      cursor: pointer;
    }
  }

  .sidebar-title-selected {
    font-weight: 600;
    color: #000000;
    // cursor: default;
  }

  .sidebar-item {
    border-bottom: 1px solid #E9E9EA;
    width: 282px;
    height: 66px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    color: #212223;
    font-size: 16px;
    line-height: 18px;
    padding: 24px;
  }

  .sidebar-item:hover {
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
  }

  .sidebar-item-selected {
    border-bottom: 1px solid #E9E9EA;
    border-right: 6px solid #173C4D;
    width: 282px;
    height: 66px;
    color: #000000;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    padding: 24px;
  }

  .events-container {
    position: relative;
    left: 282px;
    width: 1000px;
    overflowAnchor: none
  }

  .events {
    .no-events-message {
      width: 295px;
      display: flex;
      justify-content: left;
      padding: 36px 0px 12px 22px;
      font-weight: $ix-font-weight-400;
      font-size: 18px;
      font-family: Inter;
      align-items: center;
      color: #666666;
    }

    .namespace-description {
      justify-content: left;
      padding: 12px 0px 0px 22px;
      font-weight: $ix-font-weight-400;
      font-size: 18px;
      font-family: Inter;
      color: #333333;
      width: 90%;
      word-wrap: break-word;
    }

    .event-registrations {
      width: 100%;
      display: inline-flex;
      align-items: flex-start;
      flex-wrap: wrap;
      box-sizing: border-box;
      padding: 24px 0px 12px 22px;

      .event-registration {
        margin: 12px 24px 12px 0px;
        width: 282px;
        height: 200px;
        border: 1px solid #BDBEC0;
        box-sizing: border-box;
        text-decoration: none;

        &:hover {
          cursor: pointer;
          border: 1px solid #173C4D;
          box-shadow: 0px 10px 20px rgba(37, 51, 75, 0.2);
        }

        .event-registration-name {
          font-family: Bitter;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 14px;
          white-space: nowrap;
          color: #173C4D;
          padding: 10px 24px 12px 24px;
          box-sizing: border-box;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
        }

        .event-registration-desc {
          font-family: Inter;
          font-weight: $ix-font-weight-400;
          font-size: $ix-font-size-m;
          line-height: 21px;
          color: #666666;
          padding: 0 24px 0 24px;
          margin: 0 0 22px 0;
          box-sizing: border-box;
          display: -webkit-box;
          height: 66px;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .event-registration-desc-two-line {
          font-family: Inter;
          font-weight: $ix-font-weight-400;
          font-size: $ix-font-size-m;
          line-height: 21px;
          color: #666666;
          padding: 0 24px 0 24px;
          margin: 0 0 22px 0;
          box-sizing: border-box;
          display: -webkit-box;
          height: 44px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .event-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px 16px 0 24px;
          box-sizing: border-box;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          text-transform: uppercase;
          color: #BDBEC0;
        }

        .event-tags {
          display: flex;
          height: 20px;
          width: 240px;
          justify-content: left;
          align-items: flex-start;
          padding: 0 12px 24px 24px;

          .text {
            height: 20px;
            font-size: 12px;
            font-weight: $ix-font-weight-400;
            font-family: Inter;
            color: #414343;
            padding: 1px 8px;
            background-color: rgba(103, 87, 127, 0.1);
            margin-right: 8px;
            border-radius: 12px;
            max-width: 150px;
            white-space: nowrap;
          }

          .lock-icon {
            font-size: $ix-font-size-m;
            color: $ix-color-grey-60;
            font-weight: $ix-font-weight-600;
            background: #F0F1F1;
            border-radius: 4px;
            width: 24px;
            height: 24px;
            text-align: center;
          }

          label {
            font-size: $ix-font-size-s;
            font-weight: $ix-font-weight-600;
            color: #7C377B;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }

  .events-progress-text {
    width: 100%;
    font-weight: 400;
    font-size: 18px;
  }

  .events-error-text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    height: 100px;
  }

  .no-items-found {
    justify-content: center;
    padding-top: $ix-space-padding-l;
    font-weight: $ix-font-weight-400;
    font-size: 18px;
    font-family: Inter;
    padding-left: 22px;
    align-items: center;
    color: #666666;
  }
}
