@import "../app/_globals";


.JoinTeamModel {
  $modal-max-width: 500px;
  max-width: $modal-max-width;

  .header {
    h3 {
      color: $secondary-color !important;
    }
  }

  .FormComponent {
    margin-top: -32px
  }
}


