

$horizontal-spacing-between-cards: 2rem;
$vertical-spacing-between-cards: 1rem;
$spacing-between-heading-and-cards: 2rem;
$spacing-between-section-and-content: 4rem;
$card-width: calc(100% / 3);
$section-bg-dark: #F4F5F8;



@mixin home-page-cards{
  padding: $vertical-spacing-between-cards $horizontal-spacing-between-cards;
  width: $card-width;
}
