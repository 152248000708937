@import '~@impact-x/typography/_variables';
@import '~@impact-x/space/_margin';
@import "src/common/components/app/_globals";

.project-classification-content {
  line-height: 21px;

  .team-type-details {
    padding-top: $ix-space-margin-m;
    @include small-font;
    min-height: 180px;
  }

  .infra-support {
    @include small-font;
    margin-top: $ix-space-margin-m;

    .title {
      font-weight: 700;
      margin-bottom: $ix-space-margin-s;
    }

    .infra-details {
      display: table;
      width: 100% !important;

      .detail {
        display: table-row;

        .detail-field {
          width: 30%;
          display: table-cell;
          font-weight: $ix-font-weight-600;
          padding: 4px 0;
        }

        .detail-value {
          display: table-cell;
          padding: 4px 0;
        }

        .detail-value:before {
          content: ":   ";
        }
      }
    }
  }
}
