@import "~@impact-x/typography/_variables";


.SelectTeam {

  .previous-button {
    margin-top: 20px;
  }

  .content {
    margin-top: 10px;
  }

  span {
    font-size: $ix-font-size-l;
  }

  .user-not-associated-to-team {
    text-align: center;

    .main-title {
      text-align: left;
      margin-bottom: 60px;
    }
  }
}

