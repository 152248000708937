@import "../../../../node_modules/@impact-x/typography/_variables";
@import "../../../../node_modules/@impact-x/space/_padding";
@import "../../../../node_modules/@impact-x/color/_neutral";
@import '~@impact-x/styles/_divider';
@import "src/common/components/app/_globals";

.ApplicationDetails {
  position: relative;
  width: 100%;

  @include headings-color($secondary-color);

  .application-container {
    background-color: white;
    padding: 30px 30px 30px 30px;
    text-align: left;
    box-shadow: 0 2px 4px #00000029;
    margin-bottom: 40px;
    height: max-content;
    border-radius: 2px;
  }

  .nav {
    height: 30px;
    align-items: center;
  }

  .nav-text {
    display: flex;
    align-items: center;
    color: $ix-color-grey-80;
    padding: $ix-space-padding-xxs;

    i {
      padding-right: $ix-space-padding-xs;
    }
  }

  .nav-item {
    a {
      color: black;
    }

    cursor: pointer;

    .hidden-tab-text {
      visibility: hidden;
      font-weight: $ix-font-weight-700;
      height: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .nav-link.active {
    border-bottom: $secondary-color 3px solid;
    font-weight: bold;

    .ri-file-text-fill {
      font-weight: lighter !important;
    }
  }

  .nav-link:hover {
    font-weight: $ix-font-weight-700;
  }

  hr {
    @include ix-hr;
  }

  .life-span-progress {

    progress[value] {
      border-radius: 8px;
    }

    progress::-webkit-progress-bar {
      background-color: $ix-color-grey-30;
      border-radius: 8px;
      overflow: hidden;
    }

    progress::-webkit-progress-value {
      background-color: $secondary-color;
      border-radius: 8px;
      float: right;
    }

    .alert-progress::-webkit-progress-bar {
      background-color: $primary-color;
    }

    .alert-progress::-webkit-progress-value {
      border-radius: 8px;
    }

    label {
      font-size: $ix-font-size-xs;
      font-weight: 600;
      color: #616364;
      vertical-align: text-top;
      margin-left: 5px;
    }
  }
}
