@import "src/common/components/app/_globals";
@import "src/common/components/app/_button";

.ProjectSelection {
  background-color: $mist-grey;

  .project-selection-form {
    margin-top: 1rem;
    padding: 1rem;
    background: $mist-grey;

    .mt-4 {
      margin-top: 0 !important;
    }

    .FormComponent {
      .survey-title {
        font-size: 1rem !important;
      }

      button {
        font-size: 0.75rem;
      }

      select {
        font-size: 14px;
      }
    }
  }
}

.selected-projects {
  padding-bottom: 1rem;
}

