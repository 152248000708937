@import "~@impact-x/typography/_variables";
@import "src/common/components/app/globals";

.OthersTeamUnAuthorizedMessage {
  text-align: center;
  color: #808285;
  padding-bottom: 30px;
  font-size: $ix-font-size-m;
  font-weight: $ix-font-weight-600;

  div {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .regional-it-leads-title {
    margin-top: 25px;
    margin-bottom: 20px;
    text-decoration: underline;
  }

  .contact-email {
    color: #014C8B;
    font-size: $ix-font-size-m;
    text-decoration: none !important;
    border: 0;
  }
}
