@import "src/common/components/app/_globals";
@import "node_modules/@impact-x/typography/_variables";
@import "../../../../common/components/app/data-table";

.ApplicationActivities {
  @include data-table(off);

  .ApplicationActivitiesTable {
    box-shadow: 0 0 10px #d6cbcb;
  }

  .Heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
    margin-bottom: 16px;

    .title {
      font-weight: $ix-font-weight-600;
      font-size: $ix-font-size-xl;
    }
  }

  .rdt_Pagination {
    select {
      box-sizing: content-box !important;
    }
  }

  .export-csv {
    float: right;
  }

  .link {
    @include neo-link;
    color: $text-color !important;
    border-color: $text-color !important;
    font-weight: $ix-font-weight-400 !important;
  }
}
