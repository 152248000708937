@import '~@impact-x/styles/_button';
@import '~@impact-x/color/_neutral';
@import '~@impact-x/color/_brand';
@import "src/common/components/app/_globals";

@mixin impact-x-button {
  @include ix-button;
  align-items: center;
  padding: 0.625rem 1rem;
  font-style: normal;
  border-radius: 0;
  background-color: $flamingo-60;
  border: 1px solid $flamingo-60;

  &:hover {
    background-color: $flamingo-pink-darker;
    border-color: $flamingo-pink-darker;
  }

  &:focus {
    background-color: $flamingo-60;
    border-radius: 0;
    border-color: transparent;
    box-shadow: 0 0 0 3px $flamingo-160;
  }

  &:active {
    background-color: $flamingo;
    border-color: $flamingo;
    box-shadow: none;
  }

  &[aria-disabled="true"] {
    background-color: $disabled-color;
    cursor: not-allowed;
    border: 1px solid $disabled-color;
    color: $disabled-font-color;
    box-shadow: none;
  }

  &[ix-styles~='outline'] {
    @include secondary-button;
  }
}

@mixin secondary-button {
  background-color: $ix-color-talc;
  color: $flamingo-60;
  border: 1px solid $flamingo-60;

  &:hover {
    background-color: $flamingo-60;
    border-color: $flamingo-60;
    color: $ix-color-talc;
  }

  &:focus {
    background-color: $flamingo-60;
    color: $ix-color-talc;
  }

  &:active {
    background-color: $flamingo;
    border-color: $flamingo;
    color: $ix-color-talc;
  }

  &[aria-disabled="true"] {
    color: $disabled-font-color;
    background-color: $ix-color-talc;
    border: 1px solid $disabled-color;
    box-shadow: none;
  }
}

@mixin impact-x-icon-button {
  @include ix-button;
  @include neo-link-focus;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $flamingo-60 !important;
  font-size: 24px;
  border: none #FFFFFF !important;
  padding: 0 5px;
  background-color: transparent;

  &:hover, &:active, &:focus {
    border: none $background-color !important;
    background-color: transparent !important;
    color: $flamingo-pink-darker !important;
    box-shadow: none;

    &:disabled {
      color: $ix-color-grey-60 !important;
    }
  }
}

@mixin text-button {
  @include ix-button;
  @include neo-link-focus;
  color: $flamingo-60 !important;
  border: none !important;
  font-size: 0.875rem;
  line-height: 1.125rem;
  border-radius: 0;
  padding: 0;

  &:hover, &:active, &:focus {
    background-color: transparent !important;
    color: $flamingo-pink-darker !important;
    box-shadow: none;

    &:disabled {
      color: $ix-color-grey-60 !important;
    }
  }
}


