@import "src/common/components/app/_globals";
@import '~bootstrap/scss/bootstrap.scss';
@import '~@impact-x/typography/_variables';
@import '~@impact-x/space/_margin';
@import '~@impact-x/color/_neutral';
@import '~@impact-x/color/_brand';
@import '~@impact-x/styles/_input';
@import '~@impact-x/styles/_check-box';
@import '~@impact-x/styles/_text-area';
@import '~@impact-x/styles/_radio-button';
@import '~@impact-x/styles/_select';
@import "src/common/components/app/_button";

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: $secondary-color-focus-state !important;
}

.FormComponent {
  $questionElementWidth: 600px;
  $selectElementHeight: 42px;

  @include headings-color($secondary-color);

  .sv_qstn_error_bottom {
    width: $questionElementWidth !important;
  }

  .sv_qstn {
    padding-left: 3px !important;

    strong {
      font-weight: $ix-font-weight-600;
    }

    .survey-title {
      font-family: $body-font-family !important;
      font-size: $ix-font-size-l !important;
      margin-bottom: $ix-space-margin-m !important;
      font-weight: $ix-font-weight-600 !important;
      color: $text-color !important;
    }

    .survey-title + .small {
      span:not(:empty) {
        display: block;
        margin-top: calc(9px - #{$ix-space-margin-m}) !important;
        margin-bottom: $ix-space-margin-m !important;
      }
    }
  }

  .sv_row {
    .sv_p_container {
      padding-bottom: 1.5rem;

      .sv_p_title {
        padding-left: 0;
        padding-bottom: 0.5rem;

        span {
          font-family: $heading-font-family !important;
          font-size: $h5-font-size !important;
        }
      }
    }

    &:last-child {
      .sv_p_container {
        padding-bottom: 0;
      }
    }
  }

  .title {
    height: 21px;
    padding: 0;
    margin: 0;
  }

  span[ix-styles="input"] {
    width: $questionElementWidth !important;
    @include ix-input;
    display: flex;
    font-size: $body-text-size;

    input {
      flex: 1;
    }

    &[aria-disabled="true"] {
      background-color: $ix-color-grey-20;
      @include ix-type-color-muted;
      cursor: not-allowed;

      input {
        cursor: inherit;
      }
    }
  }

  .widget-datepicker {
    width: $questionElementWidth !important;
    min-height: $selectElementHeight;
    @include ix-input;
  }

  textarea {
    @include ix-textarea;
    width: $questionElementWidth !important;
    font-size: $body-text-size;
  }

  input[type='checkbox'] {
    @include ix-checkbox;
    font-size: $body-text-size;
  }

  .select2-container:focus, .select2-container:focus-within {
    border-color: $secondary-color;
    box-shadow: 0 0 0 3px $secondary-color-focus-state;
    outline: 1px solid $secondary-color;
    outline-offset: -2px;
    border-radius: 0.2em;
  }

  .select2-container--classic.select2-container--open .select2-selection--multiple {
    border: 1px solid $secondary-color-focus-state;
  }

  .select2-container {
    width: $questionElementWidth !important;

    .select2-selection--multiple {
      padding-top: 2px;
      padding-bottom: 2px;
      min-height: $selectElementHeight;
    }

    .select2-selection--single {
      @include ix-select;
      min-height: $selectElementHeight;

      &[aria-expanded="true"] {
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l4 4 4-4z'/></svg>") !important;
        background-repeat: no-repeat !important;
        background-position-x: 100% !important;
        background-position-y: 50% !important;
      }

      .select2-selection__rendered {
        padding-left: 0;
      }

      .select2-selection__arrow {
        visibility: hidden;
      }
    }
  }


  input[type='radio'] {
    @include ix-radio-button;
  }

  select {
    @include ix-select;
    font-size: $body-text-size;
    font-family: $body-font-family;
    color: $text-color;
    width: $questionElementWidth !important;
    height: max-content;
  }

  button {
    @include impact-x-button;

    &.add-btn {
      @include secondary-button;
      display: flex;
      align-items: center;
    }

    &.remove-btn {
      @include neo-link-focus;
      border: 1px solid transparent;
      background-color: transparent;
      color: $flamingo-60 !important;
      display: flex;
      align-items: center;
      min-height: $selectElementHeight;
      max-height: $selectElementHeight;

      &:hover, &:active, &:focus {
        border: 1px solid transparent;
        background-color: transparent !important;
        color: $flamingo-pink-darker !important;
        box-shadow: none;

        &:disabled {
          color: $ix-color-grey-60 !important;
        }
      }
    }
  }

  .sv-paneldynamic__add-btn {
    @include impact-x-button;
    @include secondary-button;
    display: flex;
    align-items: center;
    float: none !important;
  }

  .alert {
    margin: 0 !important;
    padding: 0;
    color: red;
  }

  .alert-danger {
    background-color: transparent;
    border-color: transparent;

    div {
      padding-bottom: 0.5rem;
    }

    div:last-child {
      padding-bottom: 0;
    }
  }

  .survey-choices {
    margin-bottom: $ix-space-margin-m;
  }

  .survey-select {
    width: 300px;
  }

  .survey-tooltip {
    display: inline-block;
    color: white;
    background-color: #1ab394;
    border-radius: 50%;
    padding: 0 7px;
    cursor: help;
  }

  table {
    td, tr, th {
      border: none;
    }

    thead {
      display: none;
    }

    tr {
      td {
        padding: 0.75rem 0 0;

        &:nth-child(2) {
          min-width: min-content !important;
        }
      }

      &:first-child {
        td {
          padding-top: 0;
        }
      }
    }
  }
}
