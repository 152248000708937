@import "../../../../common/components/app/_globals";

.Tasks {
  @include headings-color($secondary-color);

  .resource-details {
    display: flex;

    div {
      margin-top: 12px;
      margin-right: 32px;
    }
  }
}
