@import "../../../common/components/app/_globals";
@import '../../../../node_modules/@impact-x/typography/_variables';
@import '../../../../node_modules/@impact-x/color/_neutral';
@import '../../../../node_modules/@impact-x/color/_brand';

$drop-down-highlight-color: black;
$dropdown-hover-focus-font-weight: $ix-font-weight-700;

.DropdownMenu {
  #dropdown-toggle-button {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 0;
    margin: 0;
    color: inherit !important;
    background: none;
    border: none !important;
    box-shadow: none !important;

    &:after {
      border: none !important;
      content: none;
    }

    &:focus {
      outline: auto 5px;
      outline-color: Highlight;
      outline-color: -webkit-focus-ring-color;
    }
  }

  .dropdown-menu {
    .dropdown-item {
      font-weight: $ix-font-weight-400;
      font-size: $ix-font-size-s;

      &:hover, &:active {
        color: $drop-down-highlight-color;
        font-weight: $dropdown-hover-focus-font-weight;
        cursor: pointer;
        background-color: $ix-color-grey-10;
      }
    }

    .hidden-dropdown-item {
      height: 0;
      visibility: hidden;

      .dropdown-item {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        font-weight: $dropdown-hover-focus-font-weight !important;
      }
    }

    .dropdown-content {
      width: max-content;
      background-color: $ix-color-talc;
      border-radius: 4px;
      border: 1px solid $ix-color-grey-40;
      box-sizing: border-box;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      display: none;
      position: absolute;
      z-index: 1;
    }
  }
}
