@import "~@impact-x/typography/_variables";
@import "src/common/components/app/_globals";

.NeoModalDialog {
  .modal-content {
    padding: 48px 48px;
  }

  .modal-header {
    $modal-header-color: #414343;
    border: none;
    padding: 0;
    margin-bottom: 24px;

    h3 {
      font-family: $heading-font-family;
      font-weight: $ix-font-weight-700 !important;
      color: $modal-header-color;
      font-size: $h3-font-size;
    }


    .close {
      padding: 0;
      min-width: auto;
      height: auto;
      margin: -8px 0px 0px 0px;
      font-size: 40px;
      font-weight: $ix-font-weight-400;
    }
  }

  .dialogue-sub-title {
    margin-bottom: 1rem;
  }

  .dialogue-sub-title-highlighted {
    color: $error-message-color;
  }

  .dialogue-body {
    display: flex;

    i {
      margin-right: 10px;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 24px;
  }

  .modal-button {
    margin: 4px 8px 4px 0;
    min-width: 79px;
  }
}
