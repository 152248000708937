@import "src/common/components/app/_globals";

.SearchBar {
  display: flex !important;
  align-items: center;
  width: 15.625rem;
  padding: 0.625rem 0.75rem !important;
  border-radius: 0 !important;
  border-color: $onix-black-20 !important;

  .search-icon {
    font-size: 1rem;
    color: $onix-black-60;
    line-height: 1.3125rem;
  }

  input {
    width: 100%;
    padding: 0 0.5rem;

    &::placeholder {
      color: $onix-black-60;
    }
  }

  .clear-search-button {
    font-size: 1rem;
    line-height: 1.3125rem;
    padding: 0;
  }
}
