@import "src/common/components/app/globals";
@import "../../../../node_modules/@impact-x/space/_margin";

.ContainerBox {
  margin: 0 auto;
  width: $page-content-width;
  min-height: $container-min-height;

  .neo-back-button {
    margin-top: $ix-space-margin-m;
    margin-bottom: $ix-space-margin-l;
    font-size: 12px;
  }
}
