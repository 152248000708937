@import '~@impact-x/color/_neutral';
@import '~@impact-x/color/_brand';
@import '~@impact-x/space/_margin';
@import '~@impact-x/typography/_variables';
@import "src/common/components/app/_globals";

@mixin table-row-shadow($top, $left, $blur, $color) {
  -webkit-box-shadow: $top $left $blur $color;
  -moz-box-shadow: $top $left $blur $color;
  -o-box-shadow: $top $left $blur $color;
  box-shadow: $top $left $blur $color;
}

@mixin table-header($element:tr) {

  @if $element=="div" {
    min-height: 68px;
    max-height: max-content;
  } @else {
    height: 68px;
  }
}

@mixin table-header-cell($align-text:left) {
  font-size: $ix-font-size-s;
  font-weight: $ix-font-weight-700;
  color: $onix-black-80;
  padding: $ix-space-margin-m;
  text-align: $align-text;
}

@mixin table-header-hover {
  color: $ix-color-grey-80;
}

@mixin sort-icon-default {
  content: url("../../artifacts/arrow-order-disabled.svg");
}

@mixin sort-icon-asc {
  content: url("../../artifacts/arrow-order-selected.svg");
}

@mixin sort-icon-desc {
  content: url("../../artifacts/arrow-order-selected.svg");
  transform: rotate(180deg);
}

@mixin table-body {

}

@mixin table-row($highlight-on-hover:off, $element:tr) {
  font-size: $ix-font-size-s;
  font-weight: $ix-font-weight-400;
  background-color: $ix-color-talc;

  @if $element=="div" {
    min-height: 82px;
    max-height: max-content;
  } @else {
    height: 82px;
  }

  border-bottom: 1px solid $ix-color-grey-30;

  @if $highlight-on-hover=="on" {
    &:hover {
      cursor: pointer;
      transform: scale(1, 1) !important;
      -webkit-transform: scale(1, 1) !important;
      -moz-transform: scale(1, 1) !important;
      @include table-row-shadow(0, 0, 20px, rgba(0, 0, 0, 0.15));
    }
  }
}

@mixin table-row-cell($align-text:left) {
  padding: $ix-space-margin-m;
  text-align: $align-text;
  color: $onix-black-80;
}

@mixin table-row-even {
  background-color: $ix-color-grey-5;
}

@mixin impactx-table($striped, $highlight-on-hover:off, $align-text:left) {
  width: 100%;
  display: block;

  th {
    @include table-header-cell($align-text);
  }
  tr:not(:first-child) {
    @include table-row($highlight-on-hover);
  }
  @if $striped=="striped" {
    tr:nth-child(odd) {
      @include table-row-even;
    }
  }
  tr:first-child {
    @include table-header;
  }
  td {
    @include table-row-cell($align-text);
  }
}
