@import "src/common/components/app/_globals";
@import "node_modules/@impact-x/typography/_variables";

.RequestAndTasks {
  @include headings-color($secondary-color);

  .fields {
    font-weight: $ix-font-weight-600;
    padding-right: 5px;
    font-size: 16px;
  }

  .ant-tabs {
    font-family: $body-font-family !important;
  }

  .ant-tabs-tab-btn {
    &:focus, &:active, &:focus-visible {
      color: #333333 !important;
      outline: none !important;
    }
  }

  .ant-tabs-tab {
    font-weight: 400;
    font-size: 16px;
    color: #333333 !important;

    &:hover {
      color: #333333 !important;
    }
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #333333 !important;
      font-weight: 500;
    }
  }

  .ant-tabs-ink-bar {
    background-color: #634f7d;
  }

  .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid #CCCCCC !important;
  }
}
