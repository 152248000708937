@import "src/common/components/app/_globals";

.SubHeader {
  top: $sub-header-top-position;
  position: sticky;
  z-index: 1;

  nav.sc-ix-nav-s > ol:last-child:not(:first-child) {
    padding-top: 16px;
  }

  nav.sc-ix-nav .nav-menu-item.sc-ix-nav {
    line-height: 24px;
  }
}
