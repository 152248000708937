@import "src/common/components/app/data-table";
@import "../../../common/components/app/data-table";
@import "node_modules/@impact-x/typography/_variables";

.TeamActivities {
  @include data-table(off);

  .TeamActivitiesTable {
    box-shadow: 0 0 10px #d6cbcb;
  }

  .Heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
    margin-bottom: 16px;

    .title {
      font-weight: $ix-font-weight-600;
      font-size: $ix-font-size-xl;
    }
  }

  .rdt_Pagination {
    select {
      box-sizing: content-box !important;
    }

    .sc-cxNHIi {
      width: 60px !important;
    }
  }

  .panel-title {
    display: flex;
    justify-content: right;
    padding-bottom: 2rem;
  }
}
