@import "src/common/components/app/data-table";
@import "../../../../node_modules/@impact-x/typography/index";
@import "../../../../node_modules/@impact-x/color/index";

.add-members-modal {
  min-width: max-content;

  .modal-footer {
    display: none;
  }
}

.AddMembers {
  margin-top: -24px
}
