@import "../../../../../common/components/app/_globals";
@import '../../../../../../node_modules/@impact-x/styles/_input';

.EditApplicationName {
  margin-top: 32px;

  span[ix-styles="input"] {
    width: 400px !important;
    @include ix-input;
    display: flex;
    font-size: $body-text-size;

    input {
      flex: 1;
    }
  }

  .edit-application-input-box-container {
    display: flex;

    .cancel-application-name-button {
      margin-left: 10px;
    }
  }

  .application-name-validation-error {
    color: $error-message-color;
    width: max-content;
  }
}
