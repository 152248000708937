@import '~@impact-x/color/_neutral';
@import '~@impact-x/color/_brand';
@import '~@impact-x/styles/_focus-state';
@import "src/common/components/app/_globals";
@import '~@impact-x/typography/_variables';

$drop-down-highlight-color: black;
$dropdown-hover-focus-font-weight: $ix-font-weight-700;

.HeaderDropdownMenu {
  position: relative;

  .highlight {
    color: $drop-down-highlight-color !important;
    font-weight: $dropdown-hover-focus-font-weight !important;

    a, i {
      color: $drop-down-highlight-color;
    }
  }

  a {
    text-decoration: none;
    color: black;
  }

  .dropdown-button {
    background-color: $ix-color-talc;
    color: $ix-color-grey-90;
    padding: 12px;
    border: none;
    font-weight: normal;
    position: relative;

    &:hover, focus {
      color: $drop-down-highlight-color;
      font-weight: $dropdown-hover-focus-font-weight;

      a, i {
        color: $drop-down-highlight-color;
      }
    }
  }

  .ri-arrow-down-s-line {
    padding-right: 2px;
    padding-top: 2px;
  }

  .menu-title {
    display: flex;
    align-items: center;
  }

  .hidden-menu-item {
    height: 0;
    visibility: hidden;
    font-weight: $dropdown-hover-focus-font-weight;
  }

  .drop-down-menu {
    .DropdownMenu {
      .dropdown-menu {
        margin-top: 10px;

        .dropdown-item {
          padding: 8px 24px !important;
          color: #212529 !important;
        }
      }

      .dropdown-content {
        z-index: 4;
      }
    }
  }
}
