@import "~@impact-x/typography/_variables";

.SelectTeam {
  .previous-button {
    margin-top: 20px;
  }

  .content {
    margin-top: 10px;
  }

  span {
    font-size: $ix-font-size-l;
  }
}

.modal[aria-hidden=true] {
  .create-team-modal {
    opacity: 0.8;
  }
}

