@import "~@impact-x/typography/_variables";
@import "src/common/components/app/_globals";

.NonGITSMembersAlert {
  @include neo-alert;
  line-height: 1.5;
  display: flex;
  width: 600px;
  margin-left: 3px;
  margin-bottom: 32px;

  .alert-icon {
    color: $alert-border-color;
    font-size: $ix-font-size-xl;
    padding: 0 8px 0 0;
  }

  .alert-heading {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: $ix-font-weight-600;
  }

  .alert-message {
    font-size: 12px;
    font-weight: $ix-font-weight-400;
  }
}
