@import "src/common/components/app/_globals";

.CheckBox {
  input[type='checkbox'] {
    @include neo-checkbox(span);
    width: 1em;
    position: relative;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }

    & + span {
      padding-left: 0;
    }

    & + span:before {
      top: 0.15em;
      margin-left: -1em;
      pointer-events: none;
    }

    &:checked + span:after {
      top: 0.4em;
      pointer-events: none;
      margin-left: -1em;
    }
  }
}
