@import "src/common/components/app/data-table";
@import '~@impact-x/typography/_variables';
@import '~@impact-x/color/_neutral';

.ResourceTable {
  @include data-table;


  .rdt_Table {
    margin-top: 16px;
    box-shadow: 0 0 10px #d6cbcb;

    div {
      width: 100%;
    }
  }

  .rdt_TableBody {
    .rdt_TableRow {
      .rdt_TableCell {
        div {
          white-space: pre-line !important;
        }
      }

      &:hover {
        cursor: default !important;
      }
    }

    .resource-type {
      color: $ix-color-grey-80;
      font-weight: $ix-font-weight-600;
    }
  }

  .action-button {
    margin-right: 5px;
    padding: 10px 12px
  }

  .attribute-value {
    margin-bottom: 10px;
  }

  .resource {
    display: grid;
    grid-template-columns: 40% 60%;
    padding: 10px 0 10px 0;
  }

  .status-column div:first-child {
    margin-bottom: 0.5rem;
  }
}
