@import "src/common/components/app/globals";

.PageTitle {
  @include headings-color($secondary-color);

  .main-title {
    .lighter {
      @include big-all-caps-light;
    }
  }

  .sub-title {
    h3 {
      margin-top: 32px;
    }
  }
}
