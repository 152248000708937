@import "src/common/components/app/globals";
@import "~@impact-x/space/_margin";

.AdditionalSupport {
  padding-top: 3rem;
  margin-left: $ix-space-margin-l;

  .no-additional-support-message {
    @include neo-alert;
  }
}
