@import "../../components/app/_globals";
@import "../../../../node_modules/github-markdown-css/github-markdown-light.css";

.Markdown {
  font-size: 1rem;
  padding: 1.5rem;
  font-family: $body-font-family;
  font-weight: 400;
  max-height: calc(100vh - 220px);
  overflow: auto;

  h1 {
    font-size: 1.4rem !important;
    font-weight: 600 !important;
  }

  h2 {
    font-size: 1.225rem !important;
    font-weight: 600 !important;
  }

  h3 {
    font-size: 1.125rem !important;
    font-weight: 600 !important;
  }

  a {
    pointer-events: none;
  }
}
