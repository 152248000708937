@import "~@impact-x/space/_padding";
@import "~@impact-x/space/_margin";
@import "src/common/components/app/_globals";

.EditApplicationInformation {
  padding-top: $ix-space-padding-l;
  margin-left: $ix-space-margin-l;

  .alert-message {
    @include neo-alert;
  }
}
