@import "~@impact-x/space/_margin";
@import "src/common/components/app/globals";

.InfraDetails {
  .info-title {
    @include info-title;
    margin-top: 0;
    margin-bottom: $ix-space-margin-m;
  }

  .details-list {
    margin-top: 0;
  }

  .requested-infra {
    @include info-value;
    margin-top: 0;
  }

  .cost-alert {
    width: 586px;
    height: 59px;
    flex-shrink: 0;
    background: rgba(160, 105, 8, 0.10);
    color: #A06908;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    padding: 0.75rem;
    margin-bottom: 0.75rem;

    .alert-message {
      padding-left: 4px;
    }
  }
}
