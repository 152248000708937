@import "src/common/components/app/_globals";

.team-type-dialog {
  .footer {
    align-self: center;
  }
}

.TeamTypes {
  width: 100%;

  @include headings-color($secondary-color);

  .title {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .cancel-team-creation-btn {
    padding-top: 25px;
    padding-bottom: 5px;
  }
}
