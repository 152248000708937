@import "~@impact-x/typography/_variables";
@import "src/common/components/app/globals";

.ConsentCheckBox {
  line-height: 1.5rem;
  display: flex;
  width: 1218px;
  margin-left: 3px;
  padding: 0.8rem;
  background-color: $alert-background-color !important;
  border: 1px solid $alert-border-color !important;

  .check-box {
    padding: 0 8px 0 5px;
  }

  .heading {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-weight: $ix-font-weight-700;
  }

  .message {
    font-size: 16px;
    font-weight: $ix-font-weight-400;
    line-height: 24px !important;
    margin-top: 5px;
  }

  .budget-alert-message {
    margin-top: 5px;
  }

}
