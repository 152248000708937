@import "src/common/components/app/_globals";

.FeedbackButton {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  a {
    $height: 52px;
    $width: 160px;
    height: $height;
    width: $width;
    transform: rotate(270deg);
    transform-origin: center;
    margin-right: calc(-#{$width} / 2 + #{$height} / 2 - 8px);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    pointer-events: auto;

    &:hover, &:focus {
      margin-right: calc(-#{$width} / 2 + #{$height} / 2 - 2px);
    }

    i {
      position: relative;
      top: 2px;
    }
  }
}
