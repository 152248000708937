@import "src/common/components/app/_globals";
@import '~@impact-x/space/_margin';

.team-types-info {
  border: 1.25px solid #DDDDDD;
  color: $text-color;
  padding: $ix-space-margin-xl $ix-space-margin-xl;
  height: 100%;
  text-align: left;
  background: $background-color;
  transition: all .2s ease-out;
}

.team-types-info:hover {
  border-color: $secondary-color;
  box-shadow: 4px 4px 22px #00000055;
  cursor: pointer
}

