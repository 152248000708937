@import "~@impact-x/typography/_variables";
@import '~@impact-x/color/_neutral';
@import "../app/_globals";

.StepBar {
  margin: 20px 0 50px 0;

  .step-bar-container {
    width: 120%;
    padding: 0;
    margin-left: -11%;
  }

  .progressbar {
    padding-left: 0;
  }

  .progressbar li {
    list-style: none;
    display: inline-block;
    width: 25%;
    position: relative;
    text-align: center;
  }

  .progressbar li:before {
    content: "";
    width: 26px;
    height: 26px;
    border-radius: 100%;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border: 2px solid $secondary-color;
  }

  .progressbar li:after {
    content: "";
    position: absolute;
    width: 90%;
    background: $ix-color-grey-30;
    top: 20%;
    left: -45%;
    z-index: 0;
    height: 3px;
    border-radius: 3px;
    opacity: 1;
  }

  .progressbar li:first-child:after {
    content: none;
  }

  .progressbar li.active:before {
    background: $secondary-color;
    border: 2px solid $secondary-color;
  }

  .progressbar li.active:after {
    background-color: $secondary-color;
  }

  li.active {
    font-weight: $ix-font-weight-700;
  }
}
