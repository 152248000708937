@import "src/common/components/app/data-table";
@import "../../../common/components/app/globals";
@import "../../../../node_modules/@impact-x/color/_neutral";
@import "../../../../node_modules/@impact-x/styles/_divider";

.EditTeamDetails {

  hr {
    @include ix-hr;
  }
}
