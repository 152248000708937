@import "src/common/components/app/data-table";
@import "../../../node_modules/@impact-x/typography/_variables";
@import "../../../node_modules/@impact-x/space/_padding";
@import "../../../node_modules/@impact-x/color/_neutral";
@import "../../common/components/app/_globals";
@import "../../../node_modules/@impact-x/styles/_divider";

.Teams {

  .team-manage-info {
    color: $onix-black-60;
  }

  @include headings-color($secondary-color);

  .page-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .teams-container {
    margin-top: 10px;
    font-size: $ix-font-size-l;
  }

  .nav {
    height: 30px;
    align-items: center;
  }

  .nav-text {
    display: flex;
    align-items: center;
    color: $ix-color-grey-80;

    i {
      padding-right: $ix-space-padding-xs;
    }
  }

  .nav-link {
    padding: 0;
  }

  .nav-item {
    a {
      color: black;
    }

    cursor: pointer;

    .nav-link {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      margin-right: 2rem;
    }

    .hidden-tab-text {
      visibility: hidden;
      font-weight: $ix-font-weight-700;
      height: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .nav-link.active {
    border-bottom: $secondary-color 3px solid;
    font-weight: bold;

    .ri-file-text-fill {
      font-weight: lighter !important;
    }
  }

  .nav-link:hover {
    font-weight: bold;
  }

  hr {
    @include ix-hr;
  }
}
