@import '~@impact-x/color/_neutral';
@import '~@impact-x/typography/_variables';
@import 'src/common/components/app/globals';

.InfrastructureOfferingsTable {
  margin-top: 50px;
  text-align: left;
  border-collapse: collapse;
  background-color: $ix-color-grey-10;

  tr {
    width: 185px;
  }

  td {
    width: 185px;
    padding: 18px 10px;
    text-align: center;
    font-size: 13px;
    line-height: 18px;
  }

  th {
    width: 185px;
    padding: 10px;
    border-top: 2px dashed $ix-color-grey-90;
    border-bottom: 2px dashed $ix-color-grey-90;
    border-left: 2px dashed $ix-color-grey-90;
    font-size: $ix-font-size-m;
    line-height: 22px;
    font-weight: 400;
  }


  .split-cell-right {
    border-right: none !important;
    width: 92.5px !important;
  }

  .split-cell-left {
    border-left: none !important;
    width: 92.5px !important;
  }

  .logo {
    //padding: 30px 0px 40px 0px;
  }

  .split-cell-top {
    border-top: 2px dashed $ix-color-grey-90;
    border-left: 2px solid $ix-color-grey-90;
    border-right: 2px solid $ix-color-grey-90;
    padding-bottom: 5px !important;
  }

  .split-cell-bottom {
    border-bottom: 2px dashed $ix-color-grey-90;
    border-left: 2px solid $ix-color-grey-90;
    border-right: 2px solid $ix-color-grey-90;
    padding-top: 5px !important;
  }

  .no-split-cell {
    border-top: 2px dashed $ix-color-grey-90;
    border-right: 2px solid $ix-color-grey-90;
    border-left: 2px solid $ix-color-grey-90;
    border-bottom: 2px dashed $ix-color-grey-90;
  }

  .header-row {
    td {
      font-size: $ix-font-size-m !important;
      line-height: 22px;
      font-weight: 600;
      border-top: 2px dashed $ix-color-grey-90;
    }
  }

  .last-row {
    border-bottom: 2px solid $ix-color-grey-90 !important;
  }

  .header-column {
    background-color: #fff;
    width: 200px;

    .element-container {
      display: flex;
      align-items: center;
      padding: 0 20px 0 10px;

      div {
        padding-right: 10px;
      }

      .tooltip-icon {
        font-size: 20px
      }
    }
  }

  .row-title {
    margin: auto;
  }

  .offering-available {
    font-size: 30px;
    color: $ix-color-blue-dark;
  }

  .offering-not-available {
    font-size: 30px;
    color: $ix-color-grey-60;
  }
}
