.Events-Platform-Error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 160px);
  background: #FFFFFF;

  .error-message-container {
    display: flex;
    flex-direction: column;
  }

  .error-message {
    font-family: Open Sans, serif;
    font-weight: 600;
    font-style: normal;
    font-size: 26px;
    line-height: 30px;
    color: #525252;
  }

  .possible-ways-list {
    padding-top: 47px;
    font-family: Open Sans, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 50px;
    color: #525252;
  }
}
