@import "~@impact-x/space/_padding";
@import "~@impact-x/space/_margin";
@import "src/common/components/app/globals";

.ApplicationInformation {
  padding-top: $ix-space-padding-l;
  margin-left: $ix-space-margin-l;

  #edit-application-details-button {
    position: relative;
    left: -22px;
  }

  .edit-button-shape {
    border-radius: 50%;
  }

  .lifespan-extend {
    display: flex;

    .lifespan {
      min-width: 12ch;
    }

    .extend-option {
      a {
        @include neo-link
      }
    }
  }

  .ri-edit-line {
    font-size: 18px;
  }

  a {
    @include neo-link;
    font-size: 0.875rem;
  }
}
