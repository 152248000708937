@import "~@impact-x/typography";
@import '~@impact-x/space';

.EventsUserGuide {
  font-family: "Open Sans", sans-serif;
  width: 1200px;
  font-size: $ix-font-size-l;
  font-weight: $ix-font-weight-700;
  display: flex;
  margin: 48px auto 0;

  .events-user-guide-container {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    width: 100%;
  }

  .text{
    font-weight: normal !important;
    font-size: 16px !important;
  }

}
