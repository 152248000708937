@import "src/common/components/app/data-table";
@import "../../common/components/app/_globals";

.TeamDetails {

  .team-type-info {
    @include big-all-caps-light;
    font-size: 12px !important;
    margin-bottom: 10px;
  }

  h3 {
    color: #333333 !important;
    margin-bottom: 15px;
  }

  .EditTeamDetails {
    h3 {
      margin: 15px 0;
    }
  }

  .Details {
    .collaboration-type-value {
      font-weight: $ix-font-weight-600;
    }
  }

  .ant-tabs {
    font-family: $body-font-family !important;
  }
  
  .ant-tabs-tab-btn {
    &:focus, &:active, &:focus-visible {
      color: #333333 !important;
      outline: none !important;
    }
  }

  .ant-tabs-tab {
    font-weight: 400;
    font-size: 16px;
    color: #333333 !important;

    &:hover {
      color: #333333 !important;
    }
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #333333 !important;
      font-weight: 500;
    }
  }

  .ant-tabs-ink-bar {
    background-color: #634f7d;
  }

  .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid #CCCCCC !important;
  }

  .ant-tabs-top > .ant-tabs-nav {
    margin: 0 !important;
  }

}
