@import "~@impact-x/typography/_variables";
@import "~@impact-x/space/_margin";
@import "~@impact-x/space/_padding";
@import "src/common/components/app/_globals";

.ManageInfrastructure {
  position: relative;
  padding-top: $ix-space-padding-l;
  margin-left: $ix-space-margin-l;

  .need-additional-support {
    position: absolute;
    white-space: nowrap;
    margin-bottom: $ix-space-margin-s;
    margin-top: 3rem;
    padding-top: 0.5%;
    top: 0;
    right: 0;
  }

  .billing-project-not-associated-alert {
    @include neo-alert;
    margin-bottom: $ix-space-margin-s;
    margin-top: $ix-space-margin-l;
    min-width: 50%;
  }

  .FormComponent {
    .sv_row:first-of-type {
      .sv_qstn {
        padding-top: 0;
      }
    }

    .Details, .DetailsList {
      .info-title {
        margin-top: 0;
      }
    }

    .details-link:not(.circle-ci-link) {
      color: $text-color !important;
      border-color: $text-color !important;
      font-weight: $ix-font-weight-400 !important;

      &:hover {
        color: $primary-color-hover-state !important;
        border-bottom: 1px solid $primary-color-hover-state !important;
      }
    }

    .sv_qstn[name="existingAndRequestedGithubRepos"], .sv_qstn[name="existingAndRequestedGcpProjects"] {
      padding-bottom: 0.75rem;
    }

    .sv_qstn[name="githubRepos"], .sv_qstn[name="gcpProjects"] {
      padding-top: 0;
    }
  }

  .gcp-button {
    position: absolute;
    top: 500px;
    left: 70%
  }

  .create-github-repo-button {
    position: absolute;
    top: 330px;
    left: 70%
  }


  @media only screen and (min-width: 1000px) {
    .gcp-button {
      left: 69%;
    }

    .create-github-repo-button {
      left: 69%;
    }
  }

  @media only screen and (min-width: 1200px) {
    .gcp-button {
      left: 59%;
    }
    .create-github-repo-button {
      left: 59%;
    }
  }

  @media only screen and (min-width: 1400px) {
    .gcp-button {
      left: 57%;
    }
    .create-github-repo-button {
      left: 57%;
    }
  }
}
