@import '~@impact-x/color/_neutral';
@import '~@impact-x/color/_brand';
@import "~@impact-x/typography/_variables";
@import "~@impact-x/styles/_focus-state";
@import "~@impact-x/styles/_theme-variables";
@import "~@impact-x/styles/_typography";
@import '~@impact-x/space/_margin';
@import '~@impact-x/space/_padding';

$flamingo: #F2617A;
$flamingo-20: #F690A2;
$flamingo-40: #F37187;
$flamingo-60: #C24E62;
$flamingo-140: #913A49;
$flamingo-160: #612731;
$flamingo-pink-darker: #9B293C;
$amethyst: #634F7D;
$amethyst-20: #a195b1;
$amethyst-40: #827297;
$amethyst-60: #4f3f64;
$grey-custom: #F4F5F8;
$onix-black-20: #CCCCCC;
$onix-black-60: #666666;
$onix-black-80: #333333;
$onix-black-90: #1A1A1A;
$mist-grey: #EDF1F3;

$disabled-color: $onix-black-20;
$disabled-font-color: #999999;
$primary-color: $flamingo;
$secondary-color: $amethyst;
$primary-color-white-contrast: $flamingo;
$secondary-color-white-contrast: $amethyst;
$primary-color-focus-state: $flamingo-40;
$secondary-color-focus-state: $amethyst-20;
$primary-color-hover-state: $flamingo-60;
$secondary-color-hover-state: $amethyst-60;
$background-color: #fff;
$card-border-color: #BDBEC0;
$error-message-color: #FF0000;
$ix-color-red-aa: #FF0000;
$ix-color-blue-dark: #1A73BA;

$body-font-family: 'Inter';
$heading-font-family: 'Bitter';
$h1-font-size: 2rem;
$h2-font-size: 1.75rem;
$h3-font-size: 1.625rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.313rem;
$h6-font-size: 1.125rem;
$text-color: $onix-black-80;
$description-font-color: $onix-black-60;
$body-text-size: 16px;
$line-height: 1.5;

$page-content-width: 1200px;
$header-min-width: 1200px;
$header-max-width: 1600px;
$header-height: 80px;
$footer-height: 80px;
$footer-top-margin: 60px;
$sub-header-top-position: 80px;
$sub-header-position: sticky;
$container-min-height: calc(100vh - #{$header-height} - #{$footer-height + $footer-top-margin});
$alert-border-color: #CC850A;
$alert-background-color: #FAF3E7;

.cursor-pointer {
  cursor: pointer;
}

.display-none {
  display: none !important;
}

.visibility-hidden {
  visibility: hidden;
}

.mb-50 {
  margin-bottom: 50px;
}

.bg-color-white {
  background-color: white;
}

.bg-color-solitude {
  background-color: #F4F5F8;
}

@mixin neo-checkbox($element:label) {
  opacity: 0;
  position: fixed;
  width: 0;

  & + #{$element}:before {
    content: '';
    position: absolute;
    display: inline-block;
    left: 0;
    top: 0.25em;
    width: 1em;
    height: 1em;
    padding: 0.175em;
    margin-right: $ix-space-padding-s;
    background-clip: border-box;
    border: 1px solid $ix-color-grey-40;
    cursor: pointer;
  }

  & + #{$element}:after {
    position: absolute;
  }

  &:hover + #{$element}:before {
    border: 2px solid var(--secondary-color, $default-secondary-color);
  }

  &:checked + #{$element}:before {
    background-color: var(--secondary-color, $default-secondary-color);
    border-color: var(--secondary-color, $default-secondary-color);
    padding: 0;
  }

  &:checked + #{$element}:after {
    content: '';
    display: inline-block;
    height: 0.4em;
    width: 0.6em;
    border-left: 2px solid;
    border-bottom: 2px solid;
    color: $ix-color-talc;

    transform: rotate(-45deg);
    left: 0.2em;
    top: 0.5em;
  }

  &:disabled + #{$element}:before {
    border: 1px dashed $ix-color-grey-40;
    cursor: not-allowed;
  }

  &:disabled:checked + #{$element}:before {
    background-color: $ix-color-grey-40;
    border-style: solid;
  }

  &:focus + #{$element}:before {
    @include focus-state-secondary();
    border-width: 2px;
  }

  & + #{$element} {
    padding-left: $ix-space-padding-l;
    align-items: center;
    margin-bottom: $ix-space-margin-xxs;
    position: relative;

    color: $ix-type-color-dark;
    font-size: $ix-font-size-m;
    cursor: pointer;
  }

  &:disabled + #{$element} {
    @include ix-type-color-muted;

    cursor: not-allowed;
  }

  &[ix-styles~='inline'] + #{$element} {
    display: inline-flex;
    padding-right: 3em;
  }

  &[ix-styles~='s'] + #{$element} {
    font-size: $ix-font-size-s;
  }

  &[ix-styles~='l'] + #{$element} {
    font-size: $ix-font-size-l;
  }
}

@mixin neo-link {
  color: $onix-black-80 !important;
  font-weight: $ix-font-weight-400;
  text-decoration: none !important;
  border-bottom: 1px solid $onix-black-80 !important;
  cursor: pointer;

  &:focus {
    box-shadow: none !important;
  }

  &:hover {
    color: $flamingo-pink-darker !important;
    border-bottom: 1px solid $flamingo-pink-darker !important;
  }
}

@mixin neo-card {
  transition: all .2s ease-out;
  border: 1px solid $card-border-color;
  border-radius: 0;
  padding: 2rem 1.5rem;


  &:hover {
    box-shadow: 0 10px 20px rgba(37, 51, 75, 0.2);
    border: 1px solid $secondary-color;
    text-decoration: none;
    cursor: pointer;
  }
}


@mixin neo-link-focus {
  &:focus {
    outline: auto 5px !important;
    outline-color: Highlight !important;
    outline-color: -webkit-focus-ring-color !important;
  }
}

@mixin secondary-link {
  text-decoration: none;
  color: $text-color !important;

  &:hover {
    border-bottom: 1px solid $text-color !important;
    color: $text-color !important;
  }
}


@mixin big-all-caps-light {
  font-family: $body-font-family;
  font-weight: $ix-font-weight-400;
  text-transform: uppercase;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.6);
}

@mixin big-all-caps {
  font-family: $body-font-family;
  font-weight: $ix-font-weight-400;
  text-transform: uppercase;
  font-size: 24px;
  color: $text-color;
}

@mixin small-caps {
  font-family: $body-font-family;
  font-weight: $ix-font-weight-600;
  text-transform: uppercase;
  font-size: 12px;
}

@mixin bolder-text {
  font-weight: 500;
  font-family: $body-font-family;
  font-size: 1.125rem;
}

@mixin small-font {
  font-weight: $ix-font-weight-400;
  font-family: $body-font-family;
  font-size: 0.875rem;
}

@mixin info-title {
  font-size: $ix-font-size-l;
  font-weight: $ix-font-weight-600;
  margin-top: $ix-space-margin-xl;
}

@mixin info-value {
  font-size: $ix-font-size-m;
  text-align: left;
  letter-spacing: 0;
  margin-top: $ix-space-margin-m;
}

@mixin headings {
  h1, h2, h3, h4, h5, h6 {
    font-family: $heading-font-family !important;
    font-weight: $ix-font-weight-700 !important;
  }

  h1 {
    font-size: $h1-font-size !important;
  }

  h2 {
    font-size: $h2-font-size !important;
  }

  h3 {
    font-size: $h3-font-size !important;
  }

  h4 {
    font-size: $h4-font-size !important;
  }

  h5 {
    font-size: $h5-font-size !important;
  }

  h6 {
    font-size: $h6-font-size !important;
  }
}

@mixin headings-color($color) {
  h1, h2, h3, h4, h5, h6 {
    color: $color;
  }
}

@mixin neo-alert {
  background-color: $alert-background-color !important;
  border: 1px solid $alert-border-color !important;
  border-radius: 0;
  font-size: $ix-font-size-xs;
  padding: 0.5rem;
  line-height: $h5-font-size;
  width: max-content;
  max-width: 100%;
  color: $ix-color-onyx;
}
