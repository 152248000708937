@import "../../../../common/components/app/_globals";
@import "node_modules/@impact-x/typography/_variables";

.RequestDetailsHeader {
  display: flex;
  flex-direction: column;

  div, span {
    margin-bottom: 12px;
  }

  .type-and-status-info {
    display: flex;
    align-items: center;

    div {
      margin-right: 32px;
    }

    h3 {
      margin-bottom: 0;
    }

    span {
      margin-bottom: 0;
    }
  }

  .status {
    display: flex;
    align-items: center;
    font-weight: $ix-font-weight-600;
    margin: 0;

    div:first-child {
      margin-right: 1rem;
    }
  }
}
