@import "src/common/components/app/data-table";
@import "../../../../node_modules/@impact-x/typography/_variables";
@import "../../../common/components/app/globals";

.TeamMembership {

  .project-association {
    margin-top: 3px;
    margin-bottom: 0;

    .project-association-detail {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem;
      background: #F2F2F2;
      margin-top: 10px;
      color: $onix-black-80;
      font-size: 0.875rem;

      .project-associations {
        display: flex;
        flex-direction: row;
        padding-bottom: 0.5rem;

        .dissociate-project-button {
          font-size: 14px;
        }
      }

      .project-associations:last-child {
        padding-bottom: 0 !important;
      }
    }

    .info-value {
      margin-top: 0.5rem;
    }

    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      .title {
        font-weight: $ix-font-weight-600;
        font-size: 1rem;
      }
    }
  }
}
