@import "../../../../node_modules/@impact-x/typography/_variables";
@import "src/common/components/app/_globals";

.MyAppsAndServices {
  .my-applications {
    .my-applications-title {
      margin-bottom: 1.5rem;
    }

    .my-applications-content {
      display: flex;

      .search-and-filters {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 1.5rem;
      }

      .applications-list {
        display: flex;
        flex: 3;
        flex-direction: column;

        .application-count-and-export-csv {
          display: flex;
          margin-bottom: 1.5rem;
          font-size: $ix-font-size-s;
          justify-content: space-between;
        }

        .filtered-my-applications {
          margin: -0.75rem;

          .application-column {
            padding: 0.75rem;
          }
        }
      }
    }
  }
}
