@import "~@impact-x/typography/_variables";
@import "src/common/components/app/_globals";

.UserNotAssociatedToTeam {
  .not-associated-to-team {
    margin: 80px;
    text-align: center;

    .message {
      color: #808285;
      padding-bottom: 30px;
      font-size: $ix-font-size-m;
      font-weight: $ix-font-weight-600;
    }

    .contact-email {
      color: #014C8B;
      font-size: $ix-font-size-m;
      text-decoration: none !important;
      border: 0;
    }
  }
}
