@import "~@impact-x/typography";

.NeoAccordion {
  .accordion-item {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 19px;

    .accordion-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: $ix-font-size-m;
      font-weight: $ix-font-weight-700;
      padding: 12px 29px;
      background-color: white;
      border: 1px solid #BDBEC0;

      .ri-arrow-down-s-line {
        transition: transform .1s ease-in-out;
      }
    }

    .accordion-body {
      margin: 0 29px 25px;
    }

    .accordion-body.show + .accordion-header {
      .ri-arrow-down-s-line {
        transform: rotate(-180deg);
      }
    }
  }
}