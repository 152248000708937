@import "src/common/components/app/_globals";
@import "./_common.scss";


.HomePage {
  h1, h4, h6 {
    color: $text-color !important;
    font-weight: 500 !important;
  }

  .section-bg-dark {
    background: $section-bg-dark;
  }

  .content-title {
    font-size: $h3-font-size;
    font-weight: 600;
  }

  .description-section {
    width: $page-content-width;
    margin: 0 auto;
    padding: $spacing-between-section-and-content 140px;
    font-weight: 400;
    color: $text-color;
    font-size: 24px;
    line-height: 37px;
  }

  .carousel {
    .carousel-indicators {
      margin-left: calc(calc(100% - 1200px) / 2);
      justify-content: start;
      align-items: center;
      bottom: 10px;

      li {
        width: 12px;
        height: 12px;
        margin: 5px;
        border-radius: 50%;
        border-top: 1px solid white;
        border-bottom: 1px solid white;

        &.active {
          width: 15px !important;
          height: 15px !important;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .application-section {
    width: $page-content-width;
    margin: 0 auto;
    padding: $spacing-between-section-and-content 0;

    .navigation-cards {
      display: flex;
      margin: 0 (-$spacing-between-heading-and-cards);
    }
  }

  .discover-section {
    min-height: 449px;
    padding-top: $spacing-between-section-and-content;
    padding-bottom: calc(#{$spacing-between-section-and-content} - 1rem);
    width: $page-content-width;
    margin: 0 auto;

    .navigation-cards {
      display: flex;
      margin: 0 (-$spacing-between-heading-and-cards);
    }
  }

  .start-building-section {
    margin: 0 auto;
    padding-top: $spacing-between-section-and-content;
    padding-bottom: calc(#{$spacing-between-section-and-content} - #{$footer-top-margin} - 2rem);
    width: $page-content-width;

    .navigation-links {
      display: flex;
      flex-wrap: wrap;
      margin: $vertical-spacing-between-cards (-$horizontal-spacing-between-cards);
    }
  }
}
