@import "src/common/components/app/globals";

.Details {
  .info-title {
    @include info-title;
  }

  .info-value {
    @include info-value;
    white-space: pre-wrap;
  }
}
