.Catalogue {
  .SubHeader {
    nav.sc-ix-nav li:nth-child(1) {
      width: 100px !important;
    }

    nav.sc-ix-nav li:nth-child(2) {
      width: 160px !important;
    }
  }
}
