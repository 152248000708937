.ApplicationDetailsPageTitle {
  display: flex;
  justify-content: space-between;
  min-height: 125px;

  .right-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    .action-buttons {
      display: flex;

      .join-team {
        margin-left: 10px;
      }

      .exit-team {
        margin-left: 10px;
      }
    }
  }
}

