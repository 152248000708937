@import "src/common/components/app/_globals";

$link-font-size: 0.75rem;

.csv-link-neo {
  @include neo-link;
  font-size: $link-font-size;

  &:hover, &:focus {
    text-decoration: none;
  }
}
