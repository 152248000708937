@import "src/common/components/app/_globals";
@import "../_common.scss";

.InfraStructureOfferings {
  background-color: $section-bg-dark;
  padding: $spacing-between-section-and-content 0;

  .infra-offerings-content {
    width: $page-content-width;
    margin: 0 auto;

    header {
      display: flex;
      align-items: center;

      .know-more-link-button {
        margin-left: 1.5em;
        margin-bottom: 0.5em;
      }

      h1 {
        margin-bottom: 0.5rem;
        color: $text-color;
      }
    }

    section {
      display: flex;
      justify-content: space-around;
      padding: $spacing-between-heading-and-cards $spacing-between-section-and-content 0 $spacing-between-section-and-content;
    }
  }
}
