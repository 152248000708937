@import "../app/data-table";

@mixin team-status {
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.75;
  text-align: center;
  height: 25px;
}

.TeamListTable {
  @include data-table(on);

  box-shadow: 0 0 10px #d6cbcb;

  .rdt_TableBody {

    .rdt_TableRow {

      .rdt_TableCell {

        div {
          white-space: pre-line !important;
        }

        .team-status-ACTIVE {
          @include team-status;
          background-color: #517B5C;
          color: #FFFFFF;
          width: 68px;
        }

        .team-status-INACTIVE {
          @include team-status;
          border: 1px solid #B3B3B3;
          background: #EDF1F3;
          color: #4D4D4D;
          width: 82px;
        }
      }
    }
  }
}
