@import "src/common/components/app/_button";

a.impactx-link-button {
  @include impact-x-button;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none !important;
  }
}
