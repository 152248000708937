@import 'src/common/components/app/_globals';
@import "../_common.scss";

.TextNavigation {
  @include home-page-cards;

  .text-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $text-color;
    @include bolder-text;
  }

  a:hover {
    color: $text-color !important;
    text-decoration: underline !important;
  }
}
