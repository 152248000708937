@import "src/common/components/app/data-table";
@import "~@impact-x/typography/_variables";

.RequestsTable {
  @include data-table(on);

  box-shadow: 0 0 10px #d6cbcb;

  .rdt_TableBody {

    .rdt_TableRow {

      .status-column {
        font-size: 11px;
        div:first-child {
          margin-bottom: 0.5rem;
          font-weight: $ix-font-weight-600;
        }
      }

      .requested-for-type {
        font-size: 12px;
        color: $onix-black-60;
        margin-top: 3px;
      }

      .rdt_TableCell {

        div {
          white-space: pre-line !important;
        }
      }
    }

  }
}
