@import "src/common/components/app/_globals";
@import "../_common.scss";


.ImageNavigationCard {
  @include home-page-cards;


  .card {
    @include neo-card;

    min-height: 20.9375rem;
    display: flex;
    flex-direction: column;
    padding: 0;
    flex: 1;
    border-radius: 0;

    .image {
      height: 9.75rem;
      background-size: cover;
    }

    section {
      padding: 2rem 1.5rem;
      color: $text-color;

      h6 {
        text-transform: uppercase;
        margin-bottom: 1rem;
        font-weight: 500;
      }

      p {
        min-height: 4.5rem;
        font-size: 1rem;
        font-weight: 300;
      }

      i {
        padding: 1rem 0;
      }
    }
  }

  a:link, a:visited {
    text-decoration: none;
  }
}


