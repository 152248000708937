@import "~@impact-x/typography/_variables";
@import "src/common/components/app/_globals";

* {
  box-sizing: border-box !important;
}

html, body, #root, .App {
  min-height: 100vh !important;
  width: 100% !important;
  font-size: $body-text-size !important;
  font-family: $body-font-family !important;
  color: $text-color !important;
  line-height: $line-height !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0 !important;
}

a {
  @include neo-link-focus;
}

nav.sc-ix-nav {
  padding: 0 calc((100% - #{$page-content-width}) / 2) !important;

  .nav-header.sc-ix-nav {
    font-weight: $ix-font-weight-700 !important;
    font-size: 20px !important;
    padding-left: 0 !important;
    font-family: $heading-font-family !important;
  }

  ol.sc-ix-nav {
    padding-left: 0 !important;
    font-weight: $ix-font-weight-600 !important;
    font-family: $body-font-family !important;
  }
}

body {
  --primary-color: #{$primary-color-white-contrast};
  --secondary-color: #{$secondary-color-white-contrast};
  --primary-color-focus-state: #{$primary-color-focus-state};
  --secondary-color-focus-state: #{$secondary-color-focus-state};
  --primary-color-hover-state: #{$primary-color-hover-state};
  --secondary-color-hover-state: #{$secondary-color-hover-state};
  --footer-top-margin: #{$footer-top-margin};

  @include headings;

  overflow-y: overlay;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(32, 33, 36, 0.361);

    &:hover, &:active {
      background-color: rgba(32, 33, 36, 0.541);
    }
  }

  button, input, optgroup, select, textarea {
    font-family: $body-font-family !important;
  }

  #ui-datepicker-div {
    a.ui-state-active {
      border: 1px solid $secondary-color-hover-state !important;
      background: $secondary-color !important;
    }
  }
}

.App {
  position: absolute;

  .sandbox-hidden-page-info {
    text-align: center;

    .bold-text {
      font-weight: bold;
      margin-top: 250px;
    }

    .info {
      padding-top: 1rem;
    }
  }
}
