@import "src/common/components/app/globals";

.GetStarted {

  @include headings-color($secondary-color);

  .get-started-container {
    background-color: white;
    padding: 30px 30px 30px 30px;
    text-align: left;
    box-shadow: 0 2px 4px #00000029;
    margin-bottom: 40px;
    height: max-content;
    border-radius: 2px;
  }

  .tw-api-url {
    text-decoration: none;
  }
}
