@import "src/common/components/app/_globals";

.Application-Error {
  position: fixed; /* Sit on top of the page content */
  top: #{$header-height};
  z-index: 2;
  left: 0;
  right: 0;
  bottom: #{$footer-height};
  font-size: 18px;
  font-weight: 600;
  background-color: #634F7D;

  .error-message-container {
    min-width: 500px;
    height: calc(80vh - #{$header-height} + #{$footer-height});
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-right: 30px;
    padding-bottom: 60px;

    .error-message-sub-container {
      width: 1000px;
      height: auto;

      .error-title {
        color: white;
        font-family: $heading-font-family;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        padding-bottom: 19px;
      }

      .error-message {
        font-family: $body-font-family;
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 35px;
        color: white;
      }

      .possible-ways-list {
        display: flex;
        justify-content: space-around;
        padding-top: 47px;
        font-family: Open Sans, serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 50px;
        color: #525252;
      }
    }
  }

  .error-image-container {
    height: 400px;
    display: flex;
    align-items: flex-start;

    .error-image {
      width: 430px;
      height: 358px;
    }
  }

  .link-button {
    cursor: pointer;
    font-family: $body-font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    background-color: #f2617a;
    border: 1px solid #f2617a;
    box-sizing: border-box;
    border-radius: 4px;
    color: white;
    height: 45px;
    width: 190px;
  }
}
