@import "../../../common/components/app/data-table";
@import '~@impact-x/typography/variables';


.RequestActivities {
  @include data-table;

  .Heading {
    display: flow-root;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .RequestActivitiesTable {
    box-shadow: 0 0 10px #d6cbcb;
  }

  .rdt_Pagination {
    select {
      box-sizing: content-box !important;
    }
  }

  .export-csv {
    float: right;
  }
 
  .impact-x-button{
    font-size: 12px !important;
    color: #BD4257 !important;
    border: 1px solid #BD4257 !important;
    padding: 9px 8px !important;  
    &:hover {
      color: #fff !important;
    }
  
  }
}
