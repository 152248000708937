@import "src/common/components/app/_globals";

.BackButton {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;

  a {
    @include secondary-link;
  }

  .left-arrow {
    margin-left: -4px;
  }
}
