#progress {
  .text {
      padding-top: 10px;
      padding-left: 10px;
  }

  .spinner {
    margin-bottom: 30px;
    width: 50px;
    height: 50px;
  }
}
