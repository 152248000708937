@import 'src/common/components/app/_globals';

.NotPartOfGITSLegend {
  margin-bottom: 5px;
  font-size: 12px;
  padding-left: 3px;

  .legend-title {
    color: $flamingo;
  }
}
