.DownloadDeveloperToolModal {
  $modal-max-width: 720px;
  max-width: $modal-max-width;

  .FormComponent {
    .sv_qstn_error_bottom, textarea {
      width: 100% !important;
    }

    margin-top: -32px
  }
}


