@use "sass:map";
@import "~@impact-x/space/_padding";
@import "src/common/components/app/_globals";

.Panel {
  padding: $ix-space-padding-l 0;

  .panel-title {
    display: flex;
    align-items: center;

    .title-info {
      margin-left: 5px;
    }

    h5 {
      margin: 0;
    }
  }
}

@mixin panel-title($size:medium) {
  .panel-title {
    h5 {
      @if $size==small {
        font-size: $h6-font-size !important;
      }
      @if $size==medium {
        font-size: $h5-font-size !important;
      }
    }
  }
}
