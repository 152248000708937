@import "src/common/components/app/data-table";
@import "../../../../node_modules/@impact-x/typography/_variables";
@import "src/common/components/app/_globals";

.MyTeams {
  margin-top: 3.5rem;

  .teams-count-and-export {
    margin-bottom: 1rem;
    display: flex;
    flex: 1;

    .teams-count {
      margin: auto;
      font-family: $body-font-family;
      font-size: $ix-font-size-s;
      opacity: 0.7 !important;
      flex: 1;
    }
  }

  .filter-and-search {
    margin-bottom: 2rem;
    display: flex;
    align-items: flex-start;
    flex: 1;

    .my-team-search {
      margin-left: auto;
      margin-top: 2rem;
    }

    .my-team-filter {
      margin-right: 1rem;
    }

    .reset-my-team-filter {
      margin-top: 2.675rem;
    }
  }
}
