@import "src/common/components/app/_globals";
@import "node_modules/@impact-x/typography/_variables";

.AllApplicationsFilters {
  display: flex;
  flex-direction: column;
  border: 1px solid $onix-black-20;
  background-color: $mist-grey;
  padding: 2rem 1rem;
  height: max-content;

  .title-and-reset {
    display: flex;
    margin-bottom: 1.5rem;

    .title {
      font-size: 1.125rem;
      font-weight: $ix-font-weight-700;
      margin-right: auto;
    }
  }

  .display-inactive-applications {
    display: flex;
    margin-bottom: 1.5rem;

    input[type=checkbox]:not(:checked) + span:before {
      background-color: white;
    }

    label {
      font-size: 1rem;
      margin-left: 0.5rem;
      margin-bottom: 0;
    }
  }

  .FilterDropDown {
    margin-bottom: 1.5rem;

    .multi-select {
      width: 100%;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
