@import "src/common/components/app/data-table";
@import "../../../../node_modules/@impact-x/typography/_variables";
@import "../../../../node_modules/@impact-x/color/_neutral";
@import "../../../common/components/app/_globals";
@import "../../../../node_modules/@impact-x/styles/_divider";
@import '~@impact-x/color';

.TeamInformation {
  .Panel {
    padding: 0;
  }
  .info-title {
    font-size: 16px !important;
  }
}
