@import "~@impact-x/typography/_variables";
@import "src/common/components/app/_globals";

.EventDetails {
  font-size: $ix-font-size-l;
  font-family: "Inter";
  flex: 1;

  .event-details-container {
    display: flex;
    justify-content: center;
    flex: 1;

    .back-to-events-discovery-button {
      padding-top: 22px;
      font-style: normal;
      padding-bottom: 22px;

      a {
        text-decoration: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #666666;
      }

      .left-arrow {
        color: #000000;
        width: 9.55px;
        height: 5.83px;
        margin-right: 8px;
      }
    }

    .event-registration {
      width: 860px;

      .event-height-no-tags {
        height: 230px;
      }

      .event-height-with-tags {
        height: 262px;
      }

      .event-details {
        width: 860px;
        display: inline-flex;
        flex-direction: column;
        box-sizing: border-box;
        margin-bottom: 20px;
        padding: 24px 24px 24px 24px;
        background: #FFFFFF;
        border: 1px solid #BDBEC0;

        .event-name {
          margin-top: 12px;
          font-style: normal;
          font-weight: 700;
          font-size: 21px;
          font-family: Bitter;
          line-height: 18px;
          color: #333333;
        }

        .event-category-namespace {
          font-family: Inter;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #666666;
          margin-bottom: 12px;
        }

        .event-tags {
          display: flex;
          height: 20px;
          width: 100%;
          justify-content: left;
          align-items: flex-start;
          flex-flow: row wrap;
          margin-top: 12px;

          .text {
            height: 20px;
            font-size: 12px;
            font-weight: $ix-font-weight-400;
            color: #414343;
            padding: 1px 8px;
            background-color: rgba(103, 87, 127, 0.1);
            margin-right: 8px;
            border-radius: 12px;

          }
        }

        .event-description {
          margin-top: 12px;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: #333333;

          p {
            max-height: 38px;
            margin-bottom: 6px;
          }
        }

        .publisher-details {
          display: flex;
          margin-top: 12px;
          line-height: 17px;
          align-items: center;
          font-size: 14px;
          font-weight: 600;

          .publisher-details-text {
            color: #414343;
            font-size: 14px;
            font-weight: 400;

            a {
              color: #F2617A;
              font-size: 16px;
              font-weight: 600;
            }

            a:hover {
              text-decoration: underline !important;
            }
          }
        }

        .team-link {
          margin-top: 24px;
          font-size: 16px;
          font-weight: 600;
        }
      }

      .event-registration-schema {
        max-height: 671px;
        width: 860px;
        background: #FFFFFF;
        border: 1px solid #BDBEC0;
        padding: 16px 24px 24px 24px;

        .schema {
          .schema-body {
            max-height: 537px;
            overflow-y: scroll;
            overflow-x: hidden;
            margin: 24px 0 0;
            background: $onix-black-90 !important;
            color: #212223;
            font-family: JetBrains Mono;
            font-weight: 400;
            font-size: 16px;
            font-style: normal;
            line-height: 23px;
            letter-spacing: 1px;
            display: flex;
            justify-content: space-between;

            .tree-view-schema {
              margin: 1.5rem 0 1.5rem 1.5rem !important;
              height: 100%;

              ul {
                background-color: $onix-black-90 !important;
                margin: 0 0 0 0 !important;

                li {
                  padding-top: 0 !important;
                }

                li li {
                  padding-top: 4px !important;
                }
              }


              .item-type {
                color: $mist-grey !important;
              }
            }

            .copy-schema {
              color: $mist-grey !important;
              margin: 1.5rem 1.5rem 1.5rem 0 !important;
              font-size: 20px;
              position: sticky !important;
              top: 24px;

              .ant-typography {
                margin-bottom: 0 !important;

                .ant-typography-copy {
                  color: $mist-grey !important;
                  background-color: $onix-black-90 !important;
                  margin: 0 !important;
                }

                .before-copy:hover {
                  color: $flamingo-pink-darker !important;
                }
              }
            }
          }

          .schema-title {
            height: 38px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 18px;
            color: #212223;
            border-bottom: 1px solid #BDBEC0;
            box-sizing: border-box;
            display: flex;
            align-items: center;

            .schema-title-text {
              padding-left: 12px;
              width: 80px;
              border-bottom: 2px solid #173c4d;
              padding-bottom: 16px;
            }
          }
        }
      }
    }

    .create-subscriptions-help-text {
      width: 316px;
      height: 188px;
      flex-direction: column;
      box-sizing: border-box;
      padding: 18px 24px 24px 24px;
      margin-left: 25px;
      margin-top: 60px;
      background: #FFFFFF;
      border: 1px solid #BDBEC0;

      .help-text {
        a {
          color: #F2617A;
          font-weight: bold;
        }

        a:hover {
          color: #F2617A;
          text-decoration: underline !important;
        }

        .api-gw-icon-container {
          height: 80px;
          width: 80px;
        }

        .api-gw-icon-container:hover {
          cursor: pointer;
        }

        .api-gw-text {
          width: 100%;
          height: 44px;
          margin-right: 17px;
          font-weight: 400;
          font-family: Inter;
          display: flex;
          flex-direction: column;
        }

        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        height: 44px;
        margin: 24px 0 12px;
      }


      .help-text-title {
        font-family: Inter;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #212223;
      }

      .help-text-user-guide {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #666666;
        height: 66px;
        width: 268px;
        line-height: 22px;
        font-family: Inter;

        a {
          color: #F2617A;
          font-weight: bold;
        }

        a:hover {
          color: #F2617A;
          text-decoration: underline !important;
        }
      }
    }
  }

  .event-details-progress-text {
    padding-top: 12px;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    font-family: Inter;
  }

  .event-details-error-text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 28px;
    font-size: 18px;
    font-weight: 600;
  }
}

