@import '~@impact-x/typography/_variables';
@import "src/common/components/app/_globals";

.CreateTeamUnauthorized {
  .message {
    margin: 80px;
    color: #808285;
    padding-bottom: 30px;
    font-size: $ix-font-size-l;
    font-weight: $ix-font-weight-600;
    text-align: center;

    .contact-email {
      @include neo-link;
    }
  }
}
