@import "~@impact-x/typography/_variables";
@import "~@impact-x/space/_margin";
@import "src/common/components/app/globals";
@import "src/common/components/panel";

.ReviewAndSubmit {
  hr {
    margin: 0;
  }

  .submit-btn {
    margin-top: $ix-space-margin-xs;
  }

  .details-title {
    @include info-title;
    margin-top: $ix-space-margin-l;
    font-size: $ix-font-size-m;
  }

  .details {
    @include info-value;
    white-space: pre-wrap;
    margin-top: $ix-space-margin-xs;
    font-size: $ix-font-size-m;
  }

  .review-title {
    margin: $ix-space-margin-l 0;

    h5 {
      margin: 0;
    }

    .edit-button {
      background: none;
      border: none;
      color: $primary-color;
      margin-bottom: 0;
      font-weight: $ix-font-weight-700;

      .icon {
        color: $primary-color;
        padding-left: $ix-space-padding-s;
        font-weight: $ix-font-weight-400;
      }
    }

    .edit-button:focus {
      border: none;
    }
  }

  .consent-check-box {
    margin-bottom: 24px;

    .consent-check-box-error-message {
      margin-top: 4px;
      font-size: 16px;
      color: #FF0000;
    }
  }

  .Panel {
    @include panel-title(small);
  }
}
