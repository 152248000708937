@import 'src/common/components/app/_globals';
@import 'node_modules/@impact-x/color/_brand';

.Error {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .error-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    text-align: center;

    .error-title {
      font-family: $heading-font-family !important;
      font-size: 2.25rem !important;
      margin: 0 0 1.5rem;
    }

    .error-subtitle {
      font-family: $body-font-family;
      font-size: $h4-font-size;
      margin-bottom: 3.875rem;
    }

    .neo-error-message {
      font-family: $body-font-family;
      font-size: 1.25rem;
      margin-bottom: 1.5rem;
      margin-top: 1rem;
    }
  }
}

.ErrorFullPage {
  background-color: $secondary-color;
  margin-bottom: (-$footer-top-margin);
  color: $ix-color-talc;
  height: calc(100vh);
}




