@import 'src/common/components/app/_globals';
@import "../_common.scss";


.NavigationCard {
  @include home-page-cards;

  h4 {
    @include big-all-caps;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
  }

  .card {
    margin-top: 1.875rem;
    overflow: hidden;
    min-height: 16.3125rem;
    border: 1px solid $card-border-color;
    border-radius: 0;
    color: $text-color;
    padding: 2rem 1.5rem;

    .card-body {
      padding: 0;
    }

    .card-title {
      display: flex;
      align-items: center;

      h6 {
        color: $text-color;
        font-weight: 500;
      }
    }

    .card-text {
      font-size: 1rem;
      font-weight: 300;
      min-height: 6.25rem;
    }

    .card-footer {
      border: none;
      background: $background-color;
      padding: 0;
    }

    .card-icon {
      margin-right: 0.75rem;
    }
  }

  a:link, a:visited {
    text-decoration: none;
  }
}

.NavigationCard:hover {
  text-decoration: none;
}
