@import "src/common/components/app/_globals";

.DeveloperToolDetailsPageTitle {
  display: flex;
  justify-content: space-between;

  .right-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    .download-source-code {
      width: 13.5rem;

      a {
        display: block;
      }
    }
  }

  .DeveloperToolName {
    @include headings-color($secondary-color);
    display: flex;
    margin-top: 16px;
  }
}

