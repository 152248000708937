@import '~@impact-x/typography/variables';

.Requests {

  .sc-ix-nav {
    font-size: 14px;
  }
  
  $taskStatus: (
      "APPROVED": #2B6168,
      "DISAPPROVED": #A10C09,
      "FAILED": #A10C09,
      "PENDING_APPROVAL": #A36A08,
      "COMPLETED": #2B6168
  );

  @each $statusName, $statusColor in $taskStatus {
    .task-status-#{$statusName} {
      color: $statusColor;
      font-weight: $ix-font-weight-700;
    }
  }

  $requestStatus: (
      "OPEN": #A36A08,
      "PENDING_VALIDATION": #A36A08,
      "IN_PROGRESS": #47A1AD,
      "CLOSED": #EDF1F3,
  );

  @each $statusName, $statusBackgroundColor in $requestStatus {
    .request-status-#{$statusName} {
      color: white;
      padding: 4px 8px;
      background: $statusBackgroundColor;
      text-transform: uppercase;
      width: max-content;
      font-size: 11px;
      font-weight: $ix-font-weight-600;


      @if $statusName == "CLOSED" {
        color: #4D4D4D;
        border: 1px solid #979797;
      }
    }
  }

  $aggregatedTaskStatus: (
      "APPROVED": #2B6168,
      "PARTIALLY_APPROVED": #CC850A,
      "DISAPPROVED": #A10C09,
  );

  @each $statusName, $statusColor in $aggregatedTaskStatus {
    .aggregated-status-#{$statusName} {
      color: $statusColor;
      font-weight: $ix-font-weight-700;
      font-size: 14px;
    }
  }
}
