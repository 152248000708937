@import '~@impact-x/color/_neutral';
@import '~@impact-x/color/_brand';
@import "src/common/components/app/_globals";

.Header {
  position: sticky;
  top: 0;
  box-shadow: 0 2px 4px #00000029;
  z-index: 2;
  width: 100%;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: $header-height;

  .Header-Inner {
    $min-spacing-between-header-and-screen: 60px;
    min-width: $header-min-width;
    max-width: $header-max-width;
    margin: 0 $min-spacing-between-header-and-screen;
    display: flex;
    align-items: center;
    flex: 1;
    height: 60px;

    .header-right {
      display: flex;
      align-items: center;

      .get-started {
        margin: 0 12px 0 0;
      }

      .user-badge {
        margin: 0 0 0 12px;
        width: 2.25rem;
        height: 2.25rem;
        background-color: $secondary-color;
        text-align: center;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;

        .user-name-initials {
          @include small-font;
          color: #fff;
        }
      }

      @mixin header-icon-buttons {
        width: 2.25rem;
        height: 2.25rem;
        text-align: center;
        border: 0;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $ix-color-talc;
        text-decoration: none;
        color: black !important;
        margin: 0 4px;

        &:hover, &:active, &:focus {
          border: 0;
          background-color: $ix-color-grey-10;
        }

        i {
          font-size: 1.5rem;
        }
      }

      .help-icon {
        @include header-icon-buttons;
        border-bottom: none !important;
      }

      .manage-menu {
        @include header-icon-buttons;

        .DropdownMenu {
          .dropdown-menu {
            margin-top: 10px;
            padding: 16px 0;

            .dropdown-item {
              padding: 8px 24px;
            }
          }

          .dropdown-content {
            z-index: 4;
          }
        }
      }
    }

    .header-logo {
      height: 1.5rem;
      display: flex;
      align-items: center;

      a {
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }
      }

      img {
        height: 1.5rem;
      }
    }

    .custom-switch .ant-switch-handle {
      display: none;
    }

    .custom-switch {
      padding: 0
    }

    .ant-switch {
      width: 80px;
      height: 30px;
      border-radius: 40px;
      color: #666666;
      background-color: #E6E6E6;
      margin: 0 4px;

      .ant-switch-inner {
        margin: 0;
        padding-inline-start: 0;
        padding-inline-end: 0;
        transition: none !important;

        .ant-switch-inner-checked {
          color: #825608 !important;
          font-size: 9px;
          line-height: 10px;
          text-align: center;
          position: relative;

          .toggle-icon {
            display: flex;
            width: 80px;
            height: 30px;

            .toggle-handle {
              width: 22px;
              height: 22px;
              margin-top: 4px;
              margin-right: 3px;
              border-radius: 50%;
              background-color: #CC850A !important;
              box-shadow: 1.95px 1.95px 2.6px 0 #00000026;
              align-content: center;

              i {
                color: #FFFFFF;
                width: 12px;
                height: 9px;
              }
            }

            .checked-content {
              width: 47px;
              margin: 5px 1px 5px 7px;
              font-weight: bold;
              line-height: 10px;
              align-content: center;
            }
          }
        }

        .ant-switch-inner-unchecked {
          color: #666666 !important;
          font-size: 9px;
          line-height: 10px;
          position: relative;
          margin: 0;

          .toggle-icon {
            display: flex;
            width: 80px;
            height: 30px;

            .toggle-handle {
              width: 22px;
              height: 22px;
              margin-top: 4px;
              margin-left: 3px;
              border-radius: 50%;
              background-color: #FFFFFF !important;
              align-content: center;
              box-shadow: 1.95px 1.95px 2.6px 0 #00000026;

              i {
                color: #666666;
                width: 12px;
                height: 9px;
              }
            }

            .unchecked-content {
              width: 38px;
              margin: 5px 13px 5px 4px;
              font-weight: bold;
              line-height: 10px;
              align-content: center;
            }
          }
        }
      }
    }

    .ant-switch.ant-switch-checked .ant-switch-handle {
      inset-inline-start: calc(100% - 24px);
    }

    .ant-switch.ant-switch-checked {
      color: #825608 !important;
      background-color: #F0DAB5;
    }
  }

  .sandbox-message {
    height: 20px;
    background-color: #F39E0C;
    text-align: center;
    position: relative;
    align-content: center;
    font: $body-font-family;
    font-weight: $ix-font-weight-600;
    font-size: $ix-font-size-xs;
    color: #333333;
  }

}

.Tooltip-toggle-button {
  color: #333333;
  height: 36px;
  width: 140px;

  .content {
    text-align: center;
  }

  .tooltip-inner {
    border-radius: 0;
    padding: 1px;
    font-size: $ix-font-size-xs;
  }
}



