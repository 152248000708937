@import "../../../common/components/app/table";
@import "../../../../node_modules/@impact-x/typography/_variables";
@import "src/common/components/app/_globals";
@import "src/common/components/app/data-table";


.TeamMembers {
  .NotPartOfGITSLegend {
    padding-left: 0;
  }

  .TeamMembersTable {
    margin-top: 16px;
    overflow: unset !important;
    box-shadow: 0 0 10px #d6cbcb;

    div {
      .rdt_Table {
        padding: 0 10px;

        .CheckBox {
          margin-right: -0.6em;
          margin-left: 0.7em;
        }

        .rdt_TableHead {

          .rdt_TableHeadRow {
            @include table-header(div);

            .rdt_TableCol {
              @include table-header-cell;

              .rdt_TableCol_Sortable {
                span {
                  svg {
                    transition-duration: unset;
                    height: max-content !important;
                    width: unset !important;
                  }
                }

                &:hover {
                  @include table-header-hover;
                }
              }
            }
          }
        }

        .rdt_TableBody {
          overflow: unset !important;

          .rdt_TableRow {
            @include table-row(off, div);

            .rdt_TableCell {
              @include table-row-cell;

              div {
                white-space: unset;
                color: $onix-black-80;
              }
            }

            &:hover {
              cursor: default;
            }
          }
        }
      }
    }

    .update-member-progress-bar {
      margin-top: 25px;

      .spinner {
        margin-bottom: 10px !important;
      }
    }

    .project-name {
      padding-bottom: 0.5rem;
    }

    .ri-information-line {
      position: relative;
      top: 2.5px;
      font-size: 16px;
      padding-left: 0.5rem;
      padding-right: 0.25rem;
      color: $onix-black-80;

      &:hover {
        color: $flamingo-pink-darker !important;
      }
    }

    .project-name:last-child {
      padding-bottom: 0 !important;
    }
  }

  .rdt_Pagination {
    select {
      box-sizing: content-box !important;
    }

    .sc-cxNHIi {
      width: 60px !important;
    }
  }

  .Heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;

    .title {
      font-weight: $ix-font-weight-600;
      font-size: $ix-font-size-xl;
    }

    .member-count {
      margin-left: 2px;
      margin-right: auto;
      padding-top: 3px;
    }

    .team-members-search {
      margin-right: 1rem;
      width: 17.625rem;
    }

    .member-actions button {
      margin-right: 1rem;
    }
  }

  .member-name-and-email {
    .member-name {
      line-height: 21px;
    }

    .member-email {
      font-size: 0.75rem;
      line-height: 18px;
      color: $onix-black-60 !important;
    }
  }

  .project-association-legend {
    font-size: 0.75rem;
  }
}

.Tooltip-members-type-count-info {
  opacity: 1 !important;
  padding: 0;

  .members-type-count {
    color: #000000;
    font-size: $ix-font-size-s;
    text-align: left;

    div {
      padding-bottom: 0.5rem;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .tooltip-inner {
    width: 170px;
    height: max-content;
    border-radius: 0;
    background-color: #FFFFFF;
    padding: 1rem;
    line-height: 1.5;
    box-shadow: 0 0 24px 4px rgba(0, 0, 0, 0.15);
  }

  .arrow {
    display: none !important;
  }
}

