@import "~@impact-x/typography";

.OktaChicletInfo {
  .okta-chiclet-description {
    margin-bottom: 1rem;
  }

  .authentication-type {
    font-weight: $ix-font-weight-600;
  }

  .provided-okta-chiclets, .requested-okta-chiclets {
    line-height: 1.6;
  }

  .okta-chiclet-notes {
    margin-top: 2rem;
  }
}
